import React, { useState } from "react";

import { useModal } from "../../../hooks";
import useApi, {
  clearCacheAtEndpoint,
  fetchFromApi,
  postToApi,
} from "../../../hooks/useApi";
import {
  Icon,
  Input,
  Form,
  FormDivider,
  Modal,
} from "../../../components/reusable";
import "./AddChannelsModal.scss";

const AddChannelsModal = ({ game, authMethod, authToken }) => {
  const auth = { authorization: authToken, authMethod };
  const [configChannel, setConfigChannel] = useState(null);
  const [searchRes, setSearchRes] = useState([]);
  const [currentChannels] = useApi(`/games/contentChannels/${game.slug}`);
  const selectChannel = (c) =>
    setConfigChannel(
      !c.id ? currentChannels.find((ch) => ch.name === c.name) || c : c
    );

  const searchForChannel = async (query) => {
    if (!query) return;
    const channels = await fetchFromApi(
      `/games/searchForContentChannel/${query}`,
      { headers: auth }
    );
    setSearchRes(channels || []);
  };

  return (
    <Modal showCloseBtn>
      <div className="AddChannelsModal flex-column flex-middle p-5">
        <h2 className="mb-5 flex-middle">
          <Icon name="logo-youtube" className="youtube mr-2 mt-2" />
          {`${configChannel && configChannel.id ? "Edit" : "Add"} Channel`}
        </h2>
        <div className="w-1-1 mt-5">
          {configChannel ? (
            <ConfigureChannel channel={configChannel} game={game} auth={auth} />
          ) : (
            <SelectChannel
              searchRes={searchRes}
              selectChannel={selectChannel}
              searchForChannel={searchForChannel}
              channels={currentChannels}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AddChannelsModal;

const SelectChannel = ({
  channels,
  searchForChannel,
  searchRes,
  selectChannel,
}) => {
  const [query, setQuery] = useState("");
  const search = () => searchForChannel(query);

  return (
    <div>
      <h4 className="mb-5">
        Add a YouTube channel to automatically import new events
      </h4>

      <div className="flex-start flex-middle mb-2">
        Search for a YouTube Channel
      </div>
      <div className="flex-middle">
        <Input
          autoFocus
          containerClass="w-1-1"
          onChange={setQuery}
          onSubmit={search}
        />
        <button className="icon-left ml-2" onClick={search}>
          <Icon name="search-outline" />
          SEARCH
        </button>
      </div>
      {searchRes.length ? (
        <div className="mt-5">
          {searchRes.map((c) => (
            <ChannelResultItem
              key={c.channelId}
              channel={c}
              onClick={() => selectChannel(c)}
            />
          ))}
        </div>
      ) : channels && channels.length ? (
        <div className="flex-column my-2 w-1-1">
          <FormDivider>OR</FormDivider>
          <h2 className="flex-middle flex-center">
            <Icon name="logo-youtube" className="youtube mr-2 mt-2" />
            Manage Channels
          </h2>
          <div className="mt-2 mx-8">
            {channels.map((c) => (
              <div
                key={c.id}
                className="channel-edit-option flex-middle cursor-pointer p-1"
                onClick={() => selectChannel(c)}
              >
                <Icon
                  name="chevron-forward-outline"
                  className="text-xl primary pt-1 mr-1"
                />
                {c.name}
              </div>
            ))}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

const ChannelResultItem = ({ channel, onClick }) => (
  <div
    className="ChannelResultItem flex cursor-pointer my-2 p-2 w-1-1"
    onClick={onClick}
  >
    <img src={channel.thumbnails.default.url} alt="" className="sm" />
    <div className="flex-column w-1-1 pl-2">
      <div className="flex-between">
        <a
          onClick={(e) => e.stopPropagation()}
          href={`https://www.youtube.com/channel/${channel.providerId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          {channel.name}
        </a>
      </div>
      <p className="text-xs">{channel.description}</p>
    </div>
  </div>
);

const ConfigureChannel = ({ channel, auth, game }) => {
  // IF CHANNEL HAS DEFAULTS, WE'RE IN EDIT MODE
  const { eventRegex, importFrequency } = channel;
  const [reqdTerm, setReqdTerm] = useState(
    eventRegex ? eventRegex.substring(2, eventRegex.length - 2) : ""
  );
  const [freq, setFreq] = useState(importFrequency || "daily");
  const [isPosting, setIsPosting] = useState(false);
  const { closeModal } = useModal();

  return (
    <div className="ConfigureChannel">
      <div className="px-5">
        Channel:{" "}
        <a
          onClick={(e) => e.stopPropagation()}
          href={`https://www.youtube.com/channel/${channel.providerId}`}
          target="_blank"
          rel="noopener noreferrer"
          className="link"
        >
          {channel.name}
        </a>
        <div className="mt-8" />
        <b className="mt-8 mb-2">How often should we check for new uploads?</b>
        <div className="">
          <div
            className="flex-middle cursor-pointer"
            onClick={() => setFreq("daily")}
          >
            <input
              type="radio"
              name="freq"
              value="daily"
              checked={freq === "daily"}
              readOnly
            />
            <label htmlFor="daily">Daily</label>
          </div>
          <div
            className="flex-middle cursor-pointer"
            onClick={() => setFreq("weekly")}
          >
            <input
              type="radio"
              name="freq"
              value="weekly"
              checked={freq === "weekly"}
              readOnly
            />
            <label htmlFor="weekly">Weekly (smaller channels)</label>
          </div>
        </div>
        <p className="text-base mt-8">
          <b>Playlist Title Filtered Term (Optional) </b>
          <span
            className="link"
            title={regexTermDescription}
            onClick={() => alert(regexTermDescription)}
          >
            What's this?
          </span>
        </p>
        <Input value={reqdTerm} onChange={setReqdTerm} />
      </div>
      <div className="my-5" />
      <Form
        isLoading={isPosting}
        onSubmit={async () => {
          setIsPosting(true);
          await postToApi(
            `/games/addContentChannel`,
            {
              GameSlug: game.slug,
              GameId: game.id,
              id: channel.id, // enables upsert on api
              name: channel.name,
              providerId: channel.providerId,
              importFrequency: freq,
              eventRegex: reqdTerm ? `.*${reqdTerm}.*` : null,
            },
            "POST",
            auth
          );
          clearCacheAtEndpoint(`/games/contentChannels/${game.slug}`);
          closeModal();
        }}
        cancelText={channel.id ? "REMOVE" : "CANCEL"}
        onCancel={
          !channel.id
            ? closeModal
            : async () => {
                const confirmed = window.confirm(
                  "Are you sure you want to remove this channel?"
                );
                if (!confirmed) return;
                await postToApi(
                  `/games/deleteContentChannel/${channel.id}`,
                  null,
                  "POST",
                  auth
                );
                clearCacheAtEndpoint(`/games/contentChannels/${game.slug}`);
                closeModal();
              }
        }
      />
    </div>
  );
};

const regexTermDescription = `This will only import playlists with the supplied term in the title.
\nIf the channel has non-tournament videos, check if their tourney playlists have a unique word or phrase that's always used in the playlist title.`;
