import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { IS_MOBILE } from "../../config/config";
import { UserContext } from "../../context/UserContext";
import { useFollowGame, useModal } from "../../hooks";
import { analytics, cache, strings } from "../../helpers";
import useApi, { fetchFromApi, usePaginatedApi } from "../../hooks/useApi";
import { CreateGameModal, EventUploadModal } from "../../components/modals";
import ModTaskList from "./ModTaskList";
import {
  ActivatedInput,
  BannerNavScreen,
  ClassSearchInput,
  FollowButton,
  Icon,
  Input,
  PaginatedScroll,
  Screen,
  Select,
  VerticalMatchList,
  VideoSection,
} from "../../components/reusable";
import "./GameScreen.scss";

const GameScreen = () => {
  const { gameSlug } = useParams();
  const { setModal } = useModal();
  const [screen, setScreen] = useState("Events");
  useEffect(() => setScreen("Events"), [gameSlug]);
  let [game, , isGameLoading] = useApi(`/games/${gameSlug}`);
  game && cache.cacheGameIcons([game]);
  const gameId = game ? game.id : null;
  game = game || {};
  const [search, setSearch] = useState();

  const { authToken, determineAuthMethod } = useContext(UserContext);
  const authMethod = determineAuthMethod(game);

  const searchForEvent = async (query) => {
    if (!query) return setSearch(null);
    const data = await fetchFromApi(`/events/search/${query}/${gameId}`);
    analytics.save("event_search_game_screen", { query, results: data });
    setSearch({ query, data });
  };
  useEffect(() => setSearch(), [game]);

  const ClearSearchBtn = () => (
    <button
      className="danger icon-left"
      onClick={() => {
        analytics.save("clear_search_res_game_screen", search);
        searchForEvent();
      }}
    >
      <Icon name="close" />
      CLEAR SEARCH
    </button>
  );

  const isMissingAdmins = game.id && !game.admins;
  return isGameLoading || game.id ? (
    <>
      <BannerNavScreen
        className="GameScreen content"
        bannerImage={game.bannerURL}
        onChange={(screen) => setScreen(screen)}
        navOptions={{
          ...{
            Events: ({ scrollRef }) => (
              <Events
                game={game}
                isGameLoading={isGameLoading}
                searchData={search}
                clearSearchBtn={() => <ClearSearchBtn />}
                scrollRef={scrollRef}
              />
            ),
            Search: ({ scrollRef }) => (
              <MatchSearch game={game} scrollRef={scrollRef} />
            ),
            // Highlights: () => <Highlights />,
            // Analysis: () => <Analysis />,
            // About: () => <AboutGame game={game} />,
          },
          ...(authMethod
            ? {
                Mods: () => (
                  <ModTaskList
                    game={game}
                    authMethod={authMethod}
                    authToken={authToken}
                  />
                ),
              }
            : {}),
        }}
        navIcons={["trophy-outline", "search", "cog-outline"]}
        hideOptions={isMissingAdmins}
        name={IS_MOBILE ? game.shortname || game.name : game.name}
        icon={!IS_MOBILE && game.iconURL}
        renderNavLeft={() => <FollowButton game={game} />}
        renderNavRight={() => (
          <>
            {screen === "Events" && (
              <>
                {authMethod && (
                  <>
                    <button
                      className="info icon-left mr-5"
                      onClick={() =>
                        setModal(
                          <CreateGameModal
                            game={game}
                            authMethod={authMethod}
                            authToken={authToken}
                          />
                        )
                      }
                    >
                      <Icon name="pencil"></Icon>
                      EDIT GAME
                    </button>
                  </>
                )}
                {search ? (
                  <ClearSearchBtn />
                ) : isMissingAdmins ? (
                  <span />
                ) : (
                  <ActivatedInput
                    icon="search-outline"
                    onSubmit={(query) => searchForEvent(query)}
                    placeholder="Search for an event.."
                  />
                )}
              </>
            )}
            {!isMissingAdmins && (
              <button
                onClick={() => {
                  analytics.save("upload_event_click", {
                    game,
                    screen: "GameScreen",
                  });
                  setModal(<EventUploadModal game={game} />);
                }}
                className="success ml-5 icon-left"
                title="Import tournament from YouTube"
              >
                <Icon name="trophy" />
                UPLOAD
              </button>
            )}
          </>
        )}
      />
    </>
  ) : (
    <Screen className="p-8">
      <h3>Game doesn't exist yet</h3>
      <p className="mt-8">
        Want this game added to GGvods? <br />
        <br />
        <a
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://forms.gle/GWNFQUw6hzD5rtVr7"
        >
          Let us know!
        </a>
      </p>
      {/* <br />
      <button
        className="success mt-5"
        onClick={() => {
          analytics.save("create_game_doesnt_exist", { gameSlug });
          setModal(<CreateGameModal />);
        }}
      >
        Create it!
      </button> */}
    </Screen>
  );
};

export default GameScreen;

const Events = (props) => {
  const [filter, setFilter] = useState("all-events");
  const {
    game: { admins, id },
  } = props;
  const drillProps = { ...props, filter, setFilter, gameId: id };
  useEffect(() => setFilter("all-events"), [id]);

  return props.searchData ? (
    <EventSearchResults searchData={props.searchData} {...drillProps} />
  ) : filter !== "all-events" ? (
    <FilteredEvents filter={filter} {...drillProps} />
  ) : id && !admins ? (
    <ComingSoon game={props.game} />
  ) : (
    <BaseGameFeed {...drillProps} />
  );
};

const BaseGameFeed = (props) => {
  const { gameSlug } = useParams();
  const [events, loadNextPage, , , isEventsLoading] = usePaginatedApi(
    `/events/game-feed/${gameSlug}`,
    1,
    null,
    ["teams"]
  );

  return (
    <GameScreenEventFeed
      {...props}
      eventData={events}
      isLoading={isEventsLoading}
      loadMore={loadNextPage}
    />
  );
};

const FilteredEvents = (props) => {
  let { filter, gameId } = props;
  const singularFilter = filter.substring(0, filter.length - 1);
  const endpoint = `/events/game/${gameId}?tier=${singularFilter}`;
  const [data, loadMore, , , isLoading] = usePaginatedApi(endpoint, 1, null, [
    "teams",
  ]);
  return (
    <GameScreenEventFeed
      {...props}
      eventData={[{ label: strings.slugToTitle(filter), data }]}
      isLoading={isLoading}
      loadMore={loadMore}
    />
  );
};

const EventSearchResults = ({ searchData: { data, query }, ...props }) =>
  data && data.length ? (
    <GameScreenEventFeed
      {...props}
      hideTierToggle
      eventData={[
        {
          label: `Search results for "${query}"`,
          data: data,
        },
      ]}
    />
  ) : (
    <div className="flex-middle p-8">
      <h2 className="mr-5">No results for "{query}" </h2>
      {props.clearSearchBtn()}
    </div>
  );

const GameScreenEventFeed = ({
  eventData,
  isLoading,
  loadMore,
  scrollRef,
  gameIcon,
  filter,
  setFilter,
  hideTierToggle = false,
}) => {
  return (
    <div className="Events p-5">
      {isLoading ? (
        <Icon name="spinner" size="xl" />
      ) : eventData && eventData.length ? (
        <PaginatedScroll
          loadMore={loadMore}
          getScrollParent={() => scrollRef.current}
        >
          {eventData &&
            eventData.map(({ label, data }, i) => (
              <VideoSection
                header={
                  i === 0 && !hideTierToggle ? (
                    <HeaderWithToggle
                      label={label}
                      filter={filter}
                      setFilter={setFilter}
                    />
                  ) : (
                    label
                  )
                }
                events={data}
                cardIcon={gameIcon}
                key={i}
              />
            ))}
        </PaginatedScroll>
      ) : (
        <h3 className="mobile-padding">No events uploaded yet.</h3>
      )}
    </div>
  );
};

const HeaderWithToggle = ({ label, filter, setFilter }) => (
  <div className="flex-between">
    <div>{label}</div>
    <Select
      value={filter}
      onChange={setFilter}
      options={["all-events", "supermajors", "majors", "locals"]}
    />
  </div>
);

const ComingSoon = ({ game }) => {
  const [isFollowing] = useFollowGame(game.slug);
  return (
    <div className="ComingSoon p-5">
      <h1>{game.name} is Coming Soon!</h1>
      <h4 className="mt-8">Want to see it sooner? Here's what to do:</h4>
      <ul className="mt-8">
        <li className="mb-5">
          <a
            className="link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://docs.google.com/forms/d/e/1FAIpQLSfxzUcbwwasIhA5kybq6K06olXfF1aS6uzH5D6eoh121w0Drw/viewform?usp=sf_link"
          >
            Apply to become a moderator
          </a>
        </li>
        {isFollowing ? (
          <li>
            <span className="success flex-middle">
              <Icon name="checkbox-outline" />
              <span className="ml-2" /> Following helps us choose what games to
              support next
            </span>
          </li>
        ) : (
          <li>
            Follow {game.name}: <FollowButton game={game} />
          </li>
        )}
      </ul>
    </div>
  );
};

// const Highlights = () => <div className="Highlights p-5">COMING SOON!</div>;
// const Analysis = () => <div className="Analysis p-5">COMING SOON!</div>;
// const AboutGame = () => <div className="About p-5">COMING SOON!</div>;

const MatchSearch = ({ game, scrollRef }) => {
  const [selectedClasses, setSelectedClasses] = useState([]);
  // eslint-disable-next-line
  const [playerNames, setPlayerNames] = useState([]);
  const [currentPlayerName, setCurrentPlayerName] = useState("");
  const [searchEndpoint, setSearchEndpoint] = useState("");
  const [searchRes, getNextPage, total, , isLoading] =
    usePaginatedApi(searchEndpoint);
  const [gameClasses] = useApi(`/games/classes/${game.slug}`, null, [
    "classes",
  ]);

  const performSearch = async () => {
    const hasClasses = selectedClasses.length;
    const hasPlayers = currentPlayerName || playerNames.length;
    const classes = hasClasses ? `classes=${selectedClasses.toString()}` : ``;
    const participants = hasPlayers
      ? `${hasClasses ? `&` : ``}participants=${playerNames.toString()}${
          playerNames.length ? `,` : ``
        }${currentPlayerName}`
      : ``;
    setSearchEndpoint(`/matches/${game.slug}/search?${classes}${participants}`);
  };

  return (
    <div className="MatchSearch p-5">
      <h2>Advanced Search</h2>
      <Input
        label="Player:"
        containerClass="w-128"
        onSubmit={performSearch}
        onChange={(n) => setCurrentPlayerName(n)}
      />
      <ClassSearchInput
        className="w-128"
        gameClasses={gameClasses}
        onSelect={(c) => c && setSelectedClasses(c)}
      />
      <button className="primary icon-left mt-5" onClick={performSearch}>
        <Icon name="search-outline" />
        SEARCH
      </button>
      {searchRes && searchRes.length ? (
        <div className="MatchSearch-results mt-8">
          <h3 className="mb-5">Search Results ({total}):</h3>
          <PaginatedScroll
            loadMore={getNextPage}
            getScrollParent={() => scrollRef.current}
          >
            <VerticalMatchList
              matches={searchRes}
              gameClasses={gameClasses}
              playersToHighlight={[currentPlayerName]}
              classesToHighlight={selectedClasses}
            />
          </PaginatedScroll>
        </div>
      ) : searchEndpoint ? (
        <h3 className="mt-8">
          {isLoading ? <Icon name="spinner" /> : "No Results"}
        </h3>
      ) : (
        <span />
      )}
    </div>
  );
};
