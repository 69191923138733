import React from "react";

import {
  FollowedGamesList,
  RecommendedGamesList,
} from "../../components/reusable";
import KeepWatchingList from "./KeepWatchingList";
import "./SideMenu.scss";

const SideMenu = () => (
  <div className="SideMenu dark" id="SideMenu">
    <div className="scrollbox">
      <div className="scrollbox-content">
        <FollowedGamesList useShortname searchButton={false} />
        <div className="mt-8" />
        <RecommendedGamesList />
        <div className="mt-8" />
        <KeepWatchingList />
      </div>
    </div>
  </div>
);

export default SideMenu;
