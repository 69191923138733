import React, { createContext } from "react";

import { IS_FIRST_VISIT, IS_MOBILE } from "../config/config";
import { Modal } from "../components/reusable";
import logo from "../assets/images/logo_pink.webp";

const AppContext = createContext({});

class AppContextProvider extends React.Component {
  state = {
    modalContent: null,
  };

  componentDidMount() {
    IS_FIRST_VISIT && this.setState({ modalContent: <FirstVisitModal /> });
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          state: this.state,
          modalContent: this.state.modalContent,
          setContext: (state) => this.setState(state),
        }}
      >
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

export { AppContextProvider, AppContext };

const FirstVisitModal = () => (
  <Modal>
    <div className="flex-middle">
      <img src={logo} alt="logo" className="w-24 m-4" />
      <h1>{!IS_MOBILE && "Welcome to "}GGvods</h1>
    </div>
    <div className="pl-8 pb-8">
      <h3>
        Watch <b>any</b> e-sports tournament,{" "}
      </h3>
      <h3 className="mt-2">
        <b>Spoiler Free.</b>
      </h3>
    </div>
  </Modal>
);
