import React, { useState } from "react";
import firebase from "@firebase/app";
import "@firebase/storage";

import { strings } from "../../../helpers";
import { Icon, ImageInput, Input } from "..";
import "./Form.scss";

export default function Form({
  fields,
  defaultValues,
  labels = {},
  onSubmit,
  submitText,
  isLoading = false,
  title,
  onCancel,
  cancelText,
  shouldUploadFiles = true,
  darkModeEnabled = true,
  children,
}) {
  const [errMsg, setErrMsg] = useState("");
  const [formVals, formSetter] = useState({});
  const setFormVals = (newData) => formSetter({ ...formVals, ...newData });

  const submitForm = (e) => {
    e.preventDefault();
    let formData = {};
    fields &&
      Object.keys(fields).forEach((field) => {
        let val = getInputValue(field, formVals, defaultValues);
        if (val || val === false) {
          val = fields[field] === "number" ? parseInt(val, 0) : val;
          formData[strings.camelCase(field)] = val;
        }
      });
    onSubmit(formData);
  };

  return (
    <form
      onSubmit={submitForm}
      className="Form flex-column flex-middle flex-center"
    >
      <legend className="text-3xl mb-2">{title}</legend>
      {fields &&
        Object.keys(fields).map(
          (field) =>
            fields[field] && (
              <FormField
                key={field}
                type={fields[field]}
                value={getInputValue(field, formVals, defaultValues)}
                field={field}
                label={labels[field]}
                setFormVals={setFormVals}
                setErrMsg={setErrMsg}
                shouldUploadFiles={shouldUploadFiles}
                darkModeEnabled={darkModeEnabled}
              />
            )
        )}
      <span className="flex-centered mt-2 w-11-12">
        <button
          className={`flex-centered w-1-1`}
          onClick={submitForm}
          style={{ paddingRight: 40 }}
          disabled={isLoading}
        >
          {isLoading ? (
            <Icon name="spinner" className="mt-1" />
          ) : (
            <div style={{ width: 42 }} />
          )}
          <div>{submitText || "SUBMIT"}</div>
        </button>
        {(onCancel || cancelText) && (
          <button
            className="danger ml-2 w-1-1"
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
          >
            {cancelText || "CANCEL"}
          </button>
        )}
      </span>
      {errMsg && <div className="danger mt-2">{errMsg}</div>}
    </form>
  );
}

const FormField = ({
  type,
  value,
  field,
  label,
  setFormVals,
  setErrMsg,
  shouldUploadFiles,
  darkModeEnabled,
}) => {
  const onChange = (inputTxt) => setFormVals({ [field]: inputTxt });
  return (
    <div className="FormField flex-column my-2 w-11-12" key={field}>
      <label className="Form-label" htmlFor="form-stacked-text">
        {label || strings.camelToTitle(field)}
      </label>
      <div className="uk-form-controls">
        {type === "text" && <FormTextArea onChange={onChange} value={value} />}

        {type === "string" && (
          <FormInput
            onChange={onChange}
            type={field === "password" ? field : "text"}
            value={value}
            field={field}
            darkModeEnabled={darkModeEnabled}
          />
        )}

        {type === "number" && (
          <FormInput
            onChange={onChange}
            type="number"
            value={value}
            darkModeEnabled={darkModeEnabled}
          />
        )}

        {type === "boolean" && (
          <label>
            <input
              type="checkbox"
              name={field}
              onChange={(e) => setFormVals({ [field]: e.target.checked })}
              checked={value}
            />
            {/* {field} */}
          </label>
        )}
        {type === "image" && (
          <ImageInput
            value={typeof value === "string" ? { iconURL: value } : value}
            onChange={(img) => {
              shouldUploadFiles
                ? uploadDocument(img.file, "/images", (err, url) => {
                    err
                      ? err.code === "storage/unauthorized" &&
                        setErrMsg("User must be logged in to upload files.")
                      : setFormVals({ [field]: url });
                  })
                : setFormVals({ [field]: img });
            }}
          />
        )}
        {type === "date" && (
          <input
            type="date"
            name="birtdate"
            placeholder="select Birth date"
            field={field}
            // onChange={}
          />
        )}
      </div>
    </div>
  );
};

const FormInput = ({ field, type, value, darkModeEnabled, onChange }) => (
  <Input
    type={type}
    value={value}
    autoComplete={field === "password" || field === "email" ? "on" : "off"}
    onChange={onChange}
    darkModeEnabled={darkModeEnabled}
    placeholder=""
  />
);

const FormTextArea = ({ onChange, value, darkModeEnabled }) => (
  <Input
    value={value}
    onChange={onChange}
    isTextArea
    placeholder={null}
    darkModeEnabled={darkModeEnabled}
  />
);

const getInputValue = (field, formVals, defaultValues) => {
  const cameled = strings.camelCase(field);

  return formVals[field] != null
    ? formVals[field]
    : defaultValues && defaultValues[field]
    ? defaultValues[field]
    : defaultValues && defaultValues[cameled]
    ? defaultValues[cameled]
    : "";
};

const FormDivider = ({ children }) => (
  <div className="Form-divider flex-row flex-middle my-5">
    <div className="Divider ml-5"></div>
    {children && <span className="info px-2">{children}</span>}
    <div className="Divider mr-5"></div>
  </div>
);

export { FormDivider };

export const uploadDocument = (
  file,
  path,
  callback,
  isAbsolutePath,
  isBlob = false
) => {
  const pushId = firebase.database().ref().push().key;
  let docRef;
  try {
    //not yet configured
    // const filePath = isAbsolutePath ? path : `${path}/${pushId}`;
    const filePath = `${path}/${pushId}`;
    docRef = firebase.storage().ref(filePath);
    // delete any old data for abs paths
    // if (isAbsolutePath) docRef.delete();
  } catch (err) {
    return callback(err);
  }

  const blob = isBlob ? file : new Blob([file], { type: "image/jpeg" });
  const uploadTask = docRef.put(blob);

  uploadTask.on(
    "state_changed",
    (snapshot) => {
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log("Upload is " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
        default:
          break;
      }
    },
    (error) => {
      console.log("upload error:", error);
      callback(error);
    },
    () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log("File available at", downloadURL);
        // const response = {
        //   url: downloadURL,
        //   id: pushId,
        // };
        callback(null, downloadURL);
      });
    }
  );
};

export const uploadDocumentSync = (image, filePath) =>
  new Promise((resolve, reject) => {
    if (!image) resolve(null);
    typeof image === "string"
      ? resolve(image)
      : image.iconURL
      ? resolve(image.iconURL)
      : uploadDocument(
          image.file || image.imgSrc,
          filePath,
          (err, url) => (err ? reject(err) : resolve(url)),
          true,
          image.imgSrc && !image.file
        );
  });
