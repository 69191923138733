import React from "react";
import useOnClickOutside from "use-onclickoutside";

import "./Dropdown.scss";
import { useRef } from "react";

const Dropdown = ({ isVisible, toggle, children }) => {
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    console.log("dropdown trigger outside");

    toggle(false);
  });
  return isVisible ? (
    <div className={`Dropdown-container`} ref={ref}>
      <div className="Dropdown-content border-radius">{children}</div>
    </div>
  ) : (
    <span />
  );
};

export default Dropdown;
