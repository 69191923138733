import React, { useContext, useState } from "react";

import { UserContext } from "../../../context/UserContext";
import { analytics } from "../../../helpers";
import { useModal } from "../../../hooks";
import { Modal, Select } from "../../reusable";

const NotificationsModal = () => {
  const {
    state: { user },
    updateUser,
  } = useContext(UserContext);

  const defaultPref = user?.notificationThreshold || "majors and supers";

  const { closeModal } = useModal();
  const [notifThreshold, setNotifThreshold] = useState(defaultPref);
  const isSaveable = notifThreshold !== defaultPref;

  return (
    <Modal showCloseBtn className="NotificationsModal">
      <div className="NotificationsModal">
        <h3>Notification Preferences</h3>
        <div className="text-xl mt-8 mb-3 primary">Followed Games:</div>
        Receive Emails for:
        <span className="ml-4"></span>
        <Select
          value={notifThreshold}
          onChange={setNotifThreshold}
          options={[
            "never",
            "only supermajors",
            "majors and supers",
            "all events",
          ]}
        />
        <button
          disabled={!isSaveable}
          className={`${isSaveable ? "success" : "info"} mt-8 w-1-1`}
          onClick={() => {
            const newSettings = {
              notificationThreshold: notifThreshold,
            };
            analytics.save("save_settings", newSettings);
            updateUser(newSettings, `/privateInfo`);
            closeModal();
          }}
        >
          SAVE
        </button>
      </div>
    </Modal>
  );
};

export default NotificationsModal;
