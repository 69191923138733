import React, { useEffect, useContext, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { CLIENT_VERSION, IS_MOBILE } from "../../config/config";
import { analytics, cache, strings } from "../../helpers";
import { useDebounce, useModal, useQuery, useToggle } from "../../hooks";
import { Dropdown, Icon, Input, Modal } from "../reusable";
import {
  LoginModal,
  NotificationsModal,
  RegisterModal,
  SettingsModal,
} from "../modals";
import discordIcon from "../../assets/images/discord_icon.png";
import "./Navbar.scss";

const Navbar = ({
  logo,
  title,
  renderDrawer,
  notifications,
  onSearch,
  searchResults = [],
  showNotifs = true,
  user,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <nav className="Navbar" id="Navbar">
      {renderDrawer &&
        renderDrawer({ isDrawerOpen, onChange: setIsDrawerOpen })}
      <span className="left">
        {renderDrawer && (
          <Icon
            name="menu-outline"
            onClick={() => setIsDrawerOpen(!isDrawerOpen)}
          />
        )}
        <Link to="/" onClick={() => analytics.save("navbar_logo_click")}>
          <img className="logo" src={logo} alt="" />
        </Link>
        <Link to="/">
          <div className="site-title">{title}</div>
        </Link>
      </span>
      {onSearch && (
        <Searchbar onSearch={onSearch} searchResults={searchResults} />
      )}
      <div className="right">
        {showNotifs && <NotifDropdown notifications={notifications} />}
        {(user || !IS_MOBILE) && user !== undefined && (
          <img
            alt=""
            src={discordIcon}
            title="Join on Discord"
            className="Nav-disc-icon cursor-pointer w-12 mr-4"
            onClick={() => window.open("https://discord.gg/jr4qMCq", "_blank")}
          />
        )}
        <AvatarDropdown user={user} />
      </div>
    </nav>
  );
};

export default Navbar;

const Searchbar = ({ onSearch, searchResults }) => {
  const history = useHistory();
  const ref = useRef();
  const [query, setQuery] = useState("");
  const [showResults, setShowResults] = useState(true);
  useDebounce(query, onSearch, 300);
  if (searchResults && searchResults.length === 0) {
    searchResults.push({ noResults: true, name: "No results found.." });
  }
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const selectSearchResult = (result) => {
    const { noResults, id, GameId, GameSlug, slug } = result;
    const route = GameId ? `/event/${GameSlug}/${id}` : `/game/${slug}`;
    if (!noResults) {
      analytics.save("search_result_selected", {
        searchResult: result,
        allResults: searchResults,
      });
      history.push(route);
    }
    hideResults();
    setQuery("");
  };

  const hideResults = () => {
    setSelectedIndex(-1);
    setShowResults(false);
  };

  return (
    <Input
      icon="search-outline"
      containerClass="Searchbar"
      autoFocus={false}
      id="Navbar-search"
      placeholder={IS_MOBILE ? "Search" : "Search for games or events.."}
      value={query}
      onChange={(txt) => setQuery(txt)}
      onClickOutside={hideResults}
      showSpinner={!searchResults}
      onFocus={() => setShowResults(true)}
      onKeyDown={(e) => {
        setShowResults(true);
        if (!searchResults) return;
        if (e.keyCode === 38) {
          // up arrow
          setSelectedIndex(
            selectedIndex === 0 ? searchResults.length - 1 : selectedIndex - 1
          );
          e.preventDefault();
          // ^stops vertical arrows from mv'ing to front and back of line
        } else if (e.keyCode === 40) {
          // down arrow
          setSelectedIndex(
            selectedIndex === searchResults.length - 1 ? 0 : selectedIndex + 1
          );
          e.preventDefault();
        } else if (e.keyCode === 13 && selectedIndex >= 0) {
          // enter key
          selectSearchResult(searchResults[selectedIndex]);
        }
      }}
    >
      {showResults && searchResults && searchResults.length > 0 && (
        <div className="Searchbar-results">
          {searchResults.map((r, i) => {
            const { noResults } = r;
            return (
              <div
                ref={ref}
                onClick={(e) => {
                  e.stopPropagation();
                  selectSearchResult(r);
                }}
                className={`Searchbar-result-item flex-middle text-base dark  ${
                  noResults ? ` info text-italic text-bold ` : ``
                }
                ${i === selectedIndex ? " bg-light-600 " : " bg-white "}
                `}
                key={i}
              >
                <div className="flex-center flex-middle">
                  {noResults ? (
                    r.name
                  ) : (
                    <>
                      <span className="w-10">
                        {r.GameId ? (
                          <Icon
                            name={`trophy-outline`}
                            className={`mr-3 text-2xl warning pt-px`}
                          />
                        ) : (
                          <img
                            src={r.iconURL}
                            className={`mr-3 text-2xl w-6 primary pt-1`}
                            alt="game icon"
                          />
                        )}
                      </span>
                      <div>
                        {r.name}
                        <div className="info text-sm">
                          {r.GameId
                            ? strings.slugToTitle(r.GameSlug)
                            : "Esport"}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </Input>
  );
};

const NotifDropdown = ({ notifications = [] }) => {
  const { toggle, isToggled } = useToggle(false);
  return (
    <div className="NotifDropdown notif-nav-item Dropdown" onClick={toggle}>
      <Icon name="notifications-outline" />
      <div
        className={`Badge bg-danger ${
          notifications.length < 1 ? "hidden" : ""
        }`}
      >
        {notifications.length}
      </div>
      <Dropdown isVisible={isToggled} toggle={toggle}>
        <div className="Menu">
          <div className="Menu-header">HEADER</div>
          <div className="Menu-item">Option 1</div>
          <div className="Menu-item">Option 2</div>
        </div>
      </Dropdown>
    </div>
  );
};

const AvatarDropdown = () => {
  const history = useHistory();
  const { setModal } = useModal();
  const { logout, user } = useContext(UserContext);
  const { toggle, isToggled } = useToggle(false);
  const chooseDropdownItem = (e, ModalComp) => {
    toggle(false);
    e.stopPropagation();
    ModalComp && setModal(<ModalComp />);
  };

  const userPhoto = user ? user.photoURL || user.imageURL : null;
  const hasAccount = cache.getCache("HAS_CREATED_ACCOUNT");
  const modalParam = useQuery().get("modal");

  useEffect(() => {
    if (modalParam === "notifications" && user) {
      setModal(<NotificationsModal />);
      history.push(`/`);
    } else if (modalParam && user === null) {
      setModal(<LoginModal />);
    }
  }, [user]); // eslint-disable-line

  return user ? (
    <div className="AvatarDropdown Dropdown" onClick={() => toggle(true)}>
      {userPhoto ? (
        <img src={userPhoto} alt="" className="Nav-avatar" />
      ) : (
        <div className="Nav-avatar bg-primary flex-middle flex-center light">
          <Icon name="person" className="text-3xl" />
        </div>
      )}
      <Dropdown isVisible={isToggled} toggle={toggle}>
        <div className="Menu">
          <div className="Menu-header">
            {user.email}
            <div className="mt-2">alpha v_{CLIENT_VERSION}</div>
          </div>
          <div className="Divider"></div>
          <div
            className="Menu-item"
            onClick={(e) => {
              analytics.save("click_dropdown", { option: "send_feedback" });
              chooseDropdownItem(e, SendFeedbackModal);
            }}
          >
            Send Feedback
            <Icon name="mail-unread-outline" className="primary ml-6" />
          </div>
          <div
            className="Menu-item"
            onClick={(e) => {
              analytics.save("click_dropdown", { option: "settings" });
              chooseDropdownItem(e, SettingsModal);
            }}
          >
            Settings
            <Icon name="cog-outline" className="primary" />
          </div>
          <div
            className="Menu-item"
            onClick={(e) => {
              analytics.save("click_dropdown", { option: "notifications" });
              chooseDropdownItem(e, NotificationsModal);
            }}
          >
            Notifications
            <Icon name="notifications-outline" className="primary" />
          </div>
          <div
            className="Menu-item"
            onClick={(e) => {
              analytics.save("click_dropdown", { option: "logout" });
              chooseDropdownItem(e);
              logout();
            }}
          >
            Logout
            <Icon name="log-out-outline" className="primary -mr-px" />
          </div>
        </div>
      </Dropdown>
    </div>
  ) : user === undefined ? (
    // first load, still not sure if we have a user yet
    <span />
  ) : (
    <button
      className={hasAccount ? `primary` : `success`}
      onClick={() => {
        analytics.save("navbar_avatar_click_no_user");
        setModal(hasAccount ? <LoginModal /> : <RegisterModal />);
      }}
    >
      {hasAccount ? "LOGIN" : "REGISTER"}
    </button>
  );
};

const SendFeedbackModal = () => (
  <Modal>
    <h2>Please send feedback</h2>
    <p>Please reach out if you have:</p>
    <ul>
      <li>Feedback on the site</li>
      <li>Experienced an error</li>
      <li>Suggestions for new features</li>
    </ul>
    <p>
      Please be sure to give a detailed explanation (and a screenshot if you've
      hit a bug) and I'll get back to you as soon as I can.
    </p>
    <div className="primary flex-middle">
      <Icon name="mail" />{" "}
      <a
        onClick={() =>
          analytics.save("click_contact_email", { modal: "send_feedback" })
        }
        href="mailto:support@ggvods.com"
        className="link ml-2"
      >
        support@ggvods.com
      </a>
    </div>
  </Modal>
);
