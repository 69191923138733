import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { analytics, strings } from "../../../helpers";
import { formatVideos } from "../../../screens/EventScreen/EventScreen";
import { usePaginatedApi } from "../../../hooks/useApi";
import InfiniteScroll from "../../reusable/PaginatedScroll/InfiniteScroll";
import { Icon, Toggle } from "../../reusable";
import "./Playlist.scss";

const Playlist = ({
  event = {},
  playlistPosition = 0,
  isHidingSpoilers,
  setIsHiding,
  watchHistory,
}) => {
  const initialApiPage = Math.ceil((playlistPosition + 1) / 25);
  const [docs, hasMore, totalVideos, , , loadPage] = usePaginatedApi(
    `/matches/event/${event.id}`,
    initialApiPage
  );
  const [matches, setMatches] = useState(null);
  const [scrollToIndex, setScrollToIndex] = useState(0);
  const scrollRef = useRef();
  const mountScrollComplete = useRef(false);
  useEffect(() => {
    const matches = isHidingSpoilers ? formatVideos(docs) : docs;
    setMatches(matches);
    if (scrollToIndex) return;
    const scrollIndex =
      matches.findIndex((m) => m.playlistPosition === playlistPosition) - 1;
    if (!scrollIndex) return;
    setScrollToIndex(scrollIndex > 0 ? scrollIndex : 0);
  }, [docs, playlistPosition, scrollToIndex, isHidingSpoilers]);

  useEffect(() => {
    // When performing initial load, will snap the playlist to the video position
    let timeout;
    if (
      docs.length &&
      docs[docs.length - 1].playlistPosition >= playlistPosition &&
      scrollToIndex &&
      !mountScrollComplete.current
    ) {
      timeout = setTimeout(() => {
        if (!scrollToIndex) return;
        scrollRef.current.scrollIntoView();
        // revert outer container scroll
        document.getElementById("Screen-content").scrollTop = 0;
        mountScrollComplete.current = true;
      }, 300);
    }
    return () => clearTimeout(timeout);
  }, [scrollToIndex, playlistPosition]); // eslint-disable-line

  const hasPrevious =
    totalVideos > 25 && initialApiPage > 1 && matches.length < 25;
  const shouldPadTop =
    matches && matches.length && hasPrevious && matches.length < 10;

  return (
    <div className="Playlist">
      {event && (
        <div className="Playlist-header">
          <Link
            to={`/event/${event.GameSlug}/${event.id}`}
            onClick={() =>
              analytics.save("playlist-header-click-to-event", { event })
            }
            className="dark hover:underline"
          >
            <h3 className="text-bold">{event && event.name}</h3>
          </Link>
          <div
            className="flex my-2 cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              analytics.save("toggle_spoilers", {
                screen: "VideoScreen",
                hideSpoilers: !isHidingSpoilers,
              });
              setIsHiding(!isHidingSpoilers);
            }}
          >
            <span className="text-2xl mr-5">Hide Spoilers</span>
            <Toggle value={isHidingSpoilers} />
          </div>
        </div>
      )}
      <div
        id="playlist-container"
        className={`Playlist-videos mt-2 border-radius-sm px-4 w-1-1`}
      >
        {matches && (
          <InfiniteScroll
            pageStart={initialApiPage}
            loadMore={loadPage}
            hasMore={typeof hasMore === "function"}
            useWindow={false}
            threshold={200}
            loader={
              <div className="loader" key={0}>
                Loading ...
              </div>
            }
          >
            {shouldPadTop && (
              <FakeItems
                amount={10 - matches.length}
                thumbnailURL={matches[0].thumbnailURL}
              />
            )}
            {matches.map((v, i) => {
              const hist = (watchHistory && watchHistory[v.id]) || {};
              return (
                <PlaylistItem
                  {...v}
                  index={i}
                  key={i}
                  isHidingSpoilers={isHidingSpoilers}
                  isPlaying={v.playlistPosition === playlistPosition}
                  watchedAmount={hist.progressPct}
                  scrollRef={scrollToIndex === i ? scrollRef : null}
                />
              );
            })}
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

const PlaylistItem = (props) => {
  let {
    containsSpoilers,
    thumbnailURL,
    name = "",
    duration,
    isPlaying,
    scrollRef,
    id,
    round,
    playlistPosition,
    watchedAmount,
    isHidingSpoilers,
    isLoading,
  } = props;
  containsSpoilers = isPlaying || watchedAmount ? false : containsSpoilers;
  const watchedPercentage =
    watchedAmount &&
    (watchedAmount < 0.01 ? 1 : (watchedAmount * 100).toFixed(0));
  const displayName = containsSpoilers
    ? round || `Round ${isLoading ? `???` : playlistPosition + 1}`
    : name;

  return (
    <Link
      to={`/video/${id}`}
      onClick={() => analytics.save("playlist_item_selected", props)}
    >
      <div
        className={`PlaylistItem flex dark${isPlaying ? " playing" : ""}
        cursor-pointer bg-white border-radius
        `}
        id={scrollRef ? "playlist-playing-item" : null}
        ref={scrollRef}
      >
        <div className="cover-img flex">
          {containsSpoilers && (
            <div className="spoiler-guard cover border-radius-sm">
              {displayName}
              <br />
              ??? vs. ???
            </div>
          )}
          <span className="overflow-hidden">
            <img
              src={thumbnailURL}
              alt=""
              className={`cover border-radius-sm  ${
                containsSpoilers ? `blurred` : ``
              }`}
            />
          </span>
          {duration &&
            (!isPlaying || !isHidingSpoilers) &&
            !containsSpoilers && (
              <div className="timebox border-radius-sm">
                {strings.formatVideoDuration(duration)}
              </div>
            )}
        </div>
        <div
          className={`preview-info p-2 flex-column flex-center ${
            isPlaying ? `info-playing` : ``
          }`}
        >
          <div>{displayName.substring(0, 33)}</div>
          <div className="flex-between flex-middle">
            <div className="info">{`Best of 3`}</div>{" "}
            <span className="flex-middle">
              {(isPlaying || watchedPercentage > 0) && (
                <>
                  <div className="info text-xs">
                    {isPlaying ? "PLAYING" : `WATCHED ${watchedPercentage}%`}
                  </div>
                  {isPlaying ? (
                    <Icon
                      name="play-circle"
                      className="success ml-1 mt-1 text-xl"
                    />
                  ) : (
                    <Icon
                      name="checkmark-circle"
                      className="primary ml-1 mt-1 text-xl"
                    />
                  )}
                </>
              )}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Playlist;

const FakeItems = ({ amount = 3, thumbnailURL }) =>
  Array.apply(null, Array(amount)).map(() => (
    <PlaylistItem
      isLoading
      containsSpoilers
      name="Loading..."
      thumbnailURL={thumbnailURL}
    />
  ));
