import { useEffect, useRef } from "react";

export default function (state, onFire, debounce = 250) {
  if (!onFire || !debounce) return;
  const stateRef = useRef(state);
  stateRef.current = state;

  const timeout = useRef();
  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      onFire(stateRef.current);
    }, debounce);
    return () => clearTimeout(timeout.current);
    //  eslint-disable-next-line
  }, [state]);

  return [timeout];
}
