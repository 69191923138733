import React from "react";
import { Link } from "react-router-dom";

import { dates, strings } from "../../../helpers";
import "./VerticalMatchList.scss";

const VerticalMatchList = ({
  matches = [],
  gameClasses = {},
  playersToHighlight = [],
  classesToHighlight = [],
}) => (
  <div className="VerticalMatchList">
    {matches.map((m, i) => (
      <Link
        key={m.id}
        to={`/video/${m.id}`}
        className="VerticalMatchList-result-item flex-wrap p-2"
      >
        <div className="mr-5">
          <img src={m.thumbnailURL} className="cover-img" alt="" />
        </div>
        <div className="result-item-details py-2">
          <b className="mb-5">{m.name}</b>
          <div className="text-italic">{dates.formatDate(m.publishedAt)}</div>
          <br />
          <div className="flex mt-2 players-list">
            <h4 className="mr-2">Players:</h4>
            {m.participants.map((p, i) => (
              <h4 key={i}>
                <span
                  className={
                    playersToHighlight.includes(strings.slugify(p))
                      ? `highlighted warning`
                      : ``
                  }
                >
                  &nbsp;
                  {deslugifyPlayer(p, m.teams)}
                </span>
                {i < m.participants.length - 1 && ","}
              </h4>
            ))}
          </div>
          {m.classes && gameClasses && (
            <div className="flex-middle">
              <div className="mr-5">
                {gameClasses.classDisplayName
                  ? strings.pluralize(gameClasses.classDisplayName)
                  : "Classes"}
                :
              </div>
              {gameClasses.classes &&
                m.classes.map((c, i) => (
                  <img
                    key={i}
                    src={
                      gameClasses.classes[c] && gameClasses.classes[c].iconURL
                    }
                    title={c}
                    alt=""
                    className={`class-icon mr-2 ${
                      classesToHighlight.includes(c) ? `highlighted` : ``
                    }`}
                  />
                ))}
            </div>
          )}
        </div>
        <div className="Divider mobile-only" />
      </Link>
    ))}
  </div>
);

export default VerticalMatchList;

const deslugifyPlayer = (playerSlug, teams) => {
  // [{players:{pink-fresh:[pit], samsora: [peach]}},{players:{ mkleo:[joker,lucina],zero:[diddy kong]} }]
  let deslugged = playerSlug;
  teams = typeof teams === "string" ? JSON.parse(teams) : teams;
  teams &&
    teams.forEach((teamObj) => {
      Object.keys(teamObj).forEach((playerName) => {
        const slugged = strings.slugify(playerName);
        if (slugged === playerSlug) {
          deslugged = playerName;
        }
      });
    });
  return deslugged;
};
