import React, { useEffect, useState, useRef } from "react";

import { Icon } from "../index";
import "./ImageInput.scss";

const ImageInput = ({
  onChange,
  value = {},
  label,
  disabled,
  noImageMessage = "",
}) => {
  let { file, imgSrc, iconURL } = value || {};
  if (typeof value === "string") iconURL = value;
  const ref = useRef();
  const InputEl = ({ showBg = false }) => (
    <>
      {showBg && (
        <div className="image-upload bg-light flex-column flex-center flex-middle cursor-pointer">
          <Icon name={"image-outline"} />
          {noImageMessage}
        </div>
      )}
      <input
        type="file"
        disabled={disabled}
        ref={ref}
        onChange={() => onChange({ file: ref.current.files[0] })}
        style={{ display: "none" }}
      />
    </>
  );

  const [isNew, setIsNew] = useState(true);
  useEffect(() => {
    !value && setIsNew(true);
  }, [value]);

  let reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      isNew && onChange({ imgSrc: reader.result, iconURL: null, file });
      reader.result && setIsNew(false);
    },
    false
  );

  file && reader.readAsDataURL(file);
  return (
    <div className="Form">
      <div className="FormField">
        <span className="ImageInput">
          {label}
          {iconURL || imgSrc ? (
            <label>
              <div className="preview-container">
                <img src={iconURL || imgSrc} alt="" className="preview-image" />
              </div>
              <InputEl />
            </label>
          ) : (
            <label>
              <InputEl showBg />
            </label>
          )}
        </span>
      </div>
    </div>
  );
};

export default ImageInput;
