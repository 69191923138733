import React from "react";
import { useHistory } from "react-router-dom";

import { cache, dates } from "../../helpers";
import { useWatchHistory } from "../../hooks";
import { PaginatedScroll, Screen } from "../../components/reusable";

const WatchHistoryScreen = () => {
  const [events, getNextPage] = useWatchHistory();
  return (
    <Screen
      className="WatchHistoryScreen content p-5"
      renderChildren={({ scrollRef }) => (
        <>
          <h2>Watch History</h2>
          <div className="VerticalMatchList mt-5">
            <PaginatedScroll
              loadMore={getNextPage}
              getScrollParent={() => scrollRef.current}
            >
              {events.map((e) => (
                <WatchedEventItem event={e} key={e.id} />
              ))}
            </PaginatedScroll>
          </div>
        </>
      )}
    />
  );
};

export default WatchHistoryScreen;

const WatchedEventItem = ({ event }) => {
  const history = useHistory();
  const { GameSlug, imageURL, name, watchHistory } = event;

  return (
    <div
      onClick={() => history.push(`/video/${watchHistory.lastWatched.matchId}`)}
      className="VerticalMatchList-result-item flex p-2 cursor-pointer"
    >
      <img src={imageURL} className="cover-img" alt="" />
      <div className={`result-item-details py-2 ml-5 flex-column flex-between`}>
        <span>
          <div className="text-2xl">{name}</div>
          <div className="mt-2">
            Watched: {dates.formatDate(watchHistory.lastWatchedAt)}
          </div>
        </span>
        <span>
          <img className="h-8" src={cache.getCachedGameIcon(GameSlug)} alt="" />
        </span>
      </div>
    </div>
  );
};
