import { useContext } from "react";

import { UserContext } from "../context/UserContext";

const useFollowGame = (GameSlug) => {
  const { user, followGame } = useContext(UserContext);
  const isFollowing =
    user && user.followedGames && user.followedGames[GameSlug];

  return [isFollowing, followGame];
};

export default useFollowGame;
