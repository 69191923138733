import React, { useContext, useState } from "react";

import { UserContext } from "../../../context/UserContext";
import { analytics, strings } from "../../../helpers";
import { clearCache, getCache, setCache } from "../../../helpers/cacheHelper";
import { useModal } from "../../../hooks";
import { FormDivider, Icon, Input, Modal, Toggle } from "../../reusable";
import "./SettingsModal.scss";

const SettingsModal = () => {
  const {
    state: { publicInfo },
    updateUser,
  } = useContext(UserContext);
  const { closeModal } = useModal();
  const { promotedAccount } = publicInfo || {};
  const persistedPlat = promotedAccount && Object.keys(promotedAccount)[0];
  const persistedName = promotedAccount ? promotedAccount[persistedPlat] : null;
  const [selectedSocial, setSocial] = useState(persistedPlat || "twitch");
  const [username, setUsername] = useState("");
  const isSaveable = selectedSocial && username;
  const [useOsDarkMode, setUseOsDarkMode] = useState(
    typeof getCache("dark-mode-enabled") !== "boolean"
  );
  const [darkModeEnabled, setDarkModeEnabled] = useState(
    isDarkMode(useOsDarkMode)
  );

  return (
    <Modal showCloseBtn className="SettingsModal">
      <div className="SettingsModal">
        <h3>User Settings</h3>
        <div className="flex flex-middle mt-4">
          <Icon name="moon" className="pt-1 mr-2" />
          <p className="mr-3 ">Dark Mode</p>
          <Toggle
            onChange={() => {
              toggleDarkMode(!darkModeEnabled);
              setDarkModeEnabled(!darkModeEnabled);
              setUseOsDarkMode(false);
            }}
            value={darkModeEnabled}
          />
        </div>
        <label className="flex flex-middle">
          <input
            type="checkbox"
            name="hello world"
            onChange={(e) => {
              const usesOs = e.target.checked;
              const isOsDark = isOsDarkEnabled();
              setUseOsDarkMode(usesOs);
              usesOs && setDarkModeEnabled(isOsDark);
              usesOs
                ? toggleDarkMode(isOsDark, true)
                : toggleDarkMode(darkModeEnabled);
            }}
            checked={useOsDarkMode}
          />
          Use Operating System Theme
        </label>
        <FormDivider />
        <p>
          Promote an account:
          <br />
          <span className="text-italic">
            {" "}
            (this can be yours, or someone you're a fan of)
          </span>
        </p>
        <span className="text-3xl flex mb-2">
          {["twitch", "youtube", "twitter", "instagram"].map((p) => (
            <SocialLogo
              key={p}
              platform={p}
              onClick={(social) => {
                analytics.save("settings_select_social", {
                  platform: "social",
                });
                setSocial(social);
              }}
              isActive={selectedSocial === p}
            />
          ))}
        </span>
        <Input
          disabled={!selectedSocial}
          label={`${strings.capitalize(selectedSocial)} Username`}
          autoFocus={selectedSocial && persistedPlat !== selectedSocial}
          onChange={(t) => setUsername(t)}
          placeholder={persistedPlat === selectedSocial ? persistedName : ""}
        />

        <button
          disabled={!isSaveable}
          className={`${isSaveable ? "success" : "info"} mt-8 w-1-1`}
          onClick={() => {
            const newSettings = {
              promotedAccount: { [selectedSocial]: username },
            };
            analytics.save("save_settings", newSettings);
            updateUser(newSettings, `/publicInfo`);
            closeModal();
          }}
        >
          SAVE
        </button>
      </div>
    </Modal>
  );
};

export default SettingsModal;

const SocialLogo = ({ platform, isActive, onClick }) => (
  <span
    className={`SocialLogo ${platform} cursor-pointer mr-5${
      isActive ? ` active` : ""
    }`}
    onClick={() => onClick(platform)}
  >
    <Icon name={`logo-${platform}`} title={strings.capitalize(platform)} />
  </span>
);

const isOsDarkEnabled = () =>
  window?.matchMedia("(prefers-color-scheme: dark)").matches;

const isDarkMode = (useOsDarkMode) => {
  const isOsDark = isOsDarkEnabled();
  const cachedVal = getCache("dark-mode-enabled");
  return useOsDarkMode
    ? isOsDark
    : typeof cachedVal === "boolean"
    ? cachedVal
    : isOsDark;
};

function toggleDarkMode(enabled, shouldClear) {
  shouldClear
    ? clearCache("dark-mode-enabled")
    : setCache("dark-mode-enabled", enabled);
  if (shouldClear) {
    document.documentElement.classList.remove("dark-mode");
    document.documentElement.classList.remove("light-mode");
  } else if (enabled) {
    document.documentElement.classList.remove("light-mode");
    document.documentElement.classList.add("dark-mode");
  } else if (enabled === false) {
    document.documentElement.classList.remove("dark-mode");
    document.documentElement.classList.add("light-mode");
  }
}
