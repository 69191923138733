import { useContext, useState, useEffect } from "react";
import firebase from "@firebase/app";
import "@firebase/database";

import { fetchFromApi } from "./useApi";
import { UserContext } from "../context/UserContext";
import { cache } from "../helpers";

let inMemEvents = [];

export const usePlaylistWatchHistory = (eventId) => {
  const [watchHistory, setWatchHistory] = useState(
    getHistoryFromCache(eventId)
  );
  const { onLogin } = useContext(UserContext);

  useEffect(() => {
    if (!eventId) return;
    const getHist = () => {
      getFbEventHistory(eventId, (hist) => {
        hist && setWatchHistory(hist);
      });
    };
    if (firebase.auth().currentUser) {
      getHist();
    } else {
      onLogin(({ watchHistory = { watchedEvents: {} } }) => {
        setWatchHistory(watchHistory.watchedEvents[eventId]);
        inMemEvents = [];
        getHist();
      });
      setWatchHistory(getHistoryFromCache(eventId));
    }
  }, [eventId, onLogin]);

  const updateHistory = (eventId, matchId, progressPct, progressTime) => {
    const newData = saveHistory(eventId, matchId, progressPct, progressTime);
    setWatchHistory({ ...watchHistory, ...newData });
  };

  return [watchHistory, updateHistory];
};

export const saveHistory = (eventId, matchId, progressPct, progressTime) => {
  const newData = {
    [matchId]: {
      lastWatchedAt: new Date().getTime(),
      progressPct,
      progressTime,
    },
  };
  updateFirebaseHistory(eventId, matchId, newData);
  updateCacheHistory(eventId, matchId, newData);
  // hist changed, force refetch for history screens
  inMemEvents = [];
  return newData;
};

const updateCacheHistory = (eventId, matchId, newData) => {
  // cached watch history follows firebase data structure
  const rootHist = cache.getCache(`watch-history`) || {};
  rootHist.watchedEvents = rootHist.watchedEvents || {};
  const cacheData = rootHist.watchedEvents || {};
  const eventHistory = { ...cacheData[eventId], ...newData };
  eventHistory.lastWatched = { ...newData[matchId], matchId };
  eventHistory.lastWatchedAt = newData.lastWatchedAt;
  rootHist.lastWatched = { ...newData[matchId], matchId, eventId };
  rootHist.watchedEvents[eventId] = eventHistory;
  cache.setCache(`watch-history`, rootHist);
};

const getHistoryRef = (eventId = "") => {
  const user = firebase.auth().currentUser;
  if (!user) return null;
  return firebase
    .database()
    .ref(`/users/${user.uid}/watchHistory/watchedEvents/${eventId}`);
};

const getLastWatchedRef = () => {
  const user = firebase.auth().currentUser;
  if (!user) return null;
  return firebase.database().ref(`/users/${user.uid}/watchHistory/lastWatched`);
};

const updateFirebaseHistory = (eventId, matchId, data) => {
  if (!firebase.auth().currentUser) return;
  matchId = parseInt(matchId);
  const matchData = data[matchId];

  // update user's last watched event, which match, timestamp
  const userLastWatched = { eventId, matchId, ...matchData };
  getLastWatchedRef().update(userLastWatched);

  // update event specific history
  const lastWatched = { matchId, ...matchData };
  return getHistoryRef(eventId).update({
    lastWatched,
    ...data,
    lastWatchedAt: matchData.lastWatchedAt,
  });
};

const useEventWatchHistory = () => {
  const { user } = useContext(UserContext);
  const [hasMore, setHasMore] = useState(false);
  const [events, setEvents] = useState(inMemEvents);

  const getNextPage = async () => {
    const lastPageDate = events.length
      ? events[events.length - 1].watchHistory.lastWatchedAt
      : undefined;

    // get user watch history metadata
    const nextPg = await getUserPaginatedFbHistory(lastPageDate);
    const hasResults = nextPg && nextPg.length;
    setHasMore(hasResults && nextPg.length % 5 === 0);
    if (!hasResults) return;

    // fetch watched events
    const nextPgEvents = await fetchFromApi(
      `/events/getEvents?eventIds=${nextPg.map((e) => e.eventId).toString()}`
    );

    // merge in watch history data
    nextPgEvents.forEach((ev) => {
      ev.watchHistory = nextPg.find((h) => h.eventId === ev.id);
    });
    const mergedEvents = [...events, ...nextPgEvents];
    setEvents(mergedEvents);
    inMemEvents = mergedEvents;
  };

  useEffect(() => {
    user && getNextPage();
  }, [user]); // eslint-disable-line

  return [events, hasMore ? getNextPage : null];
};

export default useEventWatchHistory;

const getFbEventHistory = (eventId, callback) => {
  const dbRef = getHistoryRef(eventId);
  return dbRef
    ? dbRef.on("value", (snap) => callback(snap.val()))
    : callback(null);
};

const getUserPaginatedFbHistory = (endAt = THE_FUTURE) => {
  const ref = getHistoryRef();
  return ref
    ? ref
        .orderByChild("lastWatchedAt")
        .endAt(endAt - 1)
        .limitToLast(5)
        .once("value")
        .then((snap) => snap.val())
        .then((events) =>
          events
            ? Object.entries(events)
                .map(([eventId, hist]) => ({
                  ...hist,
                  eventId: parseInt(eventId),
                }))
                .sort((a, b) => b.lastWatchedAt - a.lastWatchedAt)
            : []
        )
    : null;
};

export const getHistoryFromCache = (eventId) => {
  const hist = cache.getCache(`watch-history`) || { watchedEvents: {} };
  return hist.watchedEvents ? hist.watchedEvents[eventId] : null;
};

export const portCachedHistoryToDb = () => {
  const user = firebase.auth().currentUser;
  if (!user) return null;
  const hist = cache.getCache(`watch-history`);

  return firebase
    .database()
    .ref(`/users/${user.uid}/watchHistory`)
    .update(hist);
};

export const deleteCachedWatchHistory = () =>
  cache.setCache(`watch-history`, null);

const THE_FUTURE = 69696969696969669696969669696969;
