import React from "react";

const Icon = ({ name, className, ...props }) => {
  return (
    <div className={`${name} Icon ${className || ""}`} {...props}>
      {customIcons[name] ? (
        customIcons[name](props)
      ) : (
        <ion-icon name={name}></ion-icon>
      )}
    </div>
  );
};

export default Icon;

const customIcons = {
  spinner: (props) => <Spinner {...props} />,
};

const Spinner = ({ size }) => (
  <div className={`Spinner${size ? `-${size}` : ``} mr-5`}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
