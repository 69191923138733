import React, { useState } from "react";

import { fetchFromApi } from "../../../hooks/useApi";
import { cacheGameIcons } from "../../../helpers/cacheHelper";
import { analytics } from "../../../helpers";
import { FollowButton, Input, Modal } from "../../reusable";
import "./FollowGameModal.scss";

const FollowGameModal = () => {
  const [games, setGames] = useState(null);
  const [loading, setLoading] = useState(false);
  return (
    <div className="FollowGameModal">
      <Modal showCloseBtn>
        <div className="text-3xl mb-5">Follow a Game</div>
        <Input
          autoFocus
          placeholder="Search for a game!"
          icon="search-outline"
          debounce={400}
          onChange={async (query) => {
            if (query === "") return setGames(null);
            setLoading(true);
            const res = await fetchFromApi(`/games/search/${query}`);
            analytics.save("search_follow_game_modal", { query, results: res });
            setLoading(false);
            setGames(res);
            cacheGameIcons(res);
          }}
          containerClass="w-1-1"
          showSpinner={loading}
        />
        <br />
        {games && games.length
          ? games.map((g) => (
              <div
                key={g.id}
                className="FollowGameModal-results flex flex-middle flex-between mb-2"
              >
                <div className="flex-middle">
                  <img src={g.iconURL} alt="" className="mr-2 h-8" />
                  {g.name}
                </div>
                <FollowButton game={g} />
              </div>
            ))
          : null}
        {games && games.length === 0 && (
          <p>
            Is your favorite game missing? <br />
            <br />
            <a
              className="link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://forms.gle/GWNFQUw6hzD5rtVr7"
            >
              Let us know!
            </a>
          </p>
        )}
      </Modal>
    </div>
  );
};

export default FollowGameModal;
