import React, { useState } from "react";

import { useDebounce } from "../../../hooks";
import { Input } from "../index";
import "./SearchInput.scss";

const SearchInput = ({
  onSearch,
  searchResults,
  renderResult,
  debounce = 400,
  className,
  onSubmit,
  showSpinner = true,
  ...props
}) => {
  const clearResults = () => onSearch();
  const [query, setQuery] = useState("");
  useDebounce(query, onSearch);

  const [focusedIndex, setFocusedIndex] = useState(-1);
  const keyHandlers = {
    Enter:
      searchResults && onSubmit
        ? () => {
            setQuery("");
            onSearch();
            onSubmit(searchResults[focusedIndex > -1 ? focusedIndex : 0]);
            setFocusedIndex(-1);
          }
        : null,
    ArrowDown: () =>
      searchResults &&
      focusedIndex < searchResults.length - 1 &&
      setFocusedIndex(focusedIndex + 1),
    ArrowUp: () => focusedIndex > -1 && setFocusedIndex(focusedIndex - 1),
  };

  return (
    <Input
      containerClass={`SearchInput ${className}`}
      icon="search-outline"
      onClickOutside={clearResults}
      showSpinner={showSpinner && !searchResults}
      onChange={setQuery}
      onKeyDown={
        onSubmit
          ? ({ key }) => {
              keyHandlers[key] && keyHandlers[key]();
            }
          : null
      }
      value={query}
      {...props}
    >
      {renderResult && searchResults && searchResults.length > 0 && (
        <div className="SearchInput-results">
          {searchResults.map((r, i) => (
            <div
              key={i}
              className={`SearchInput-result-item${
                i === focusedIndex ? " focused" : ""
              }`}
              onClick={() => {
                setQuery("");
                onSearch();
              }}
            >
              {renderResult(r, i)}
            </div>
          ))}
        </div>
      )}
    </Input>
  );
};

export default SearchInput;
