import React from "react";

import { usePaginatedApi } from "../../../hooks/useApi";
import InfiniteScroll from "./InfiniteScroll";
import { Icon } from "..";

/**
 * NOTE: Parent must have .content class
 */
const PaginatedScroll = ({
  loadMore,
  pageStart = 1,
  children,
  endpoint,
  endpointOptions: o,
  renderChildren,
  ...props
}) => {
  // to download docs in parent, pass children & loadMore()
  // otherwise, pass endpoint & renderChildren()
  // STUPID BUG: add .content class to parent container for it to work
  const [docs, loadNext, total, , isLoading] = usePaginatedApi(endpoint, 1, o);
  loadMore = loadMore || loadNext;
  return (
    <InfiniteScroll
      pageStart={pageStart}
      loadMore={async (depPg) => {
        // IF WE DONT AWAIT B4 SETTING STATE, DEP WILL INFINITELY CALL LOAD MORE
        if (!loadMore) return;
        await loadMore(depPg);
      }}
      initialLoad={false}
      hasMore={loadMore !== null}
      loader={
        <div key={0} className="flex-center">
          <Icon name="spinner" size="lg" />
        </div>
      }
      useWindow={false}
      threshold={100}
      {...props}
    >
      {renderChildren
        ? renderChildren({ data: docs, total, isLoading })
        : children}
    </InfiniteScroll>
  );
};

export default PaginatedScroll;
