import React, { useContext, useState } from "react";

import { UserContext } from "../../../context/UserContext";
import { analytics } from "../../../helpers";
import { useModal } from "../../../hooks";
import { Form, Modal } from "../../reusable";
import { FormDivider } from "../../reusable/Form/Form";
import { RegisterModal } from "../index";
import SocialLoginBtn from "./SocialLoginBtn";
import "./LoginModal.scss";

const LoginModal = ({ animation, onLogin: onLoginHandler }) => {
  const { login, onLogin } = useContext(UserContext);
  const { setModal } = useModal();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [err, setErr] = useState(null);

  return (
    <div className="LoginModal">
      <Modal animation={animation}>
        <div className="flex-column flex-center flex-middle">
          <Form
            title="Login"
            fields={{ email: "string", password: "string" }}
            onSubmit={({ email, password }) => {
              setIsLoggingIn(true);
              login(email, password)
                .then((user) => {
                  setModal();
                  onLoginHandler && onLogin(onLoginHandler);
                  analytics.save("login", { provider: "email" });
                })
                .catch((err) => {
                  console.log("login err:", err);
                  setIsLoggingIn(false);
                  setErr(err.message);
                });
            }}
            submitText="LOGIN"
            isLoading={isLoggingIn}
            darkModeEnabled={false}
          ></Form>
          {err && (
            <span className="errContent danger mt-5 text-center">
              {err}
              <p
                className="link"
                onClick={() =>
                  // setModal(<RegisterModal animation={null} onLogin={onLogin} />)
                  alert("TODO")
                }
              >
                Reset my password
              </p>
            </span>
          )}
          <FormDivider>OR</FormDivider>
          <SocialLoginBtn
            type="Google"
            icon="logo-google"
            backgroundColor="#DB4437"
            onLogin={() => {
              onLoginHandler && onLogin(onLoginHandler);
              analytics.save("login", { provider: "google" });
              setModal();
            }}
          />
          <p
            className="link mt-8"
            onClick={() =>
              setModal(<RegisterModal animation={null} onLogin={onLogin} />)
            }
          >
            Create a new account
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default LoginModal;
