import React, { useState } from "react";
import { Link } from "react-router-dom";

import { strings } from "../../helpers";
import useApi, { postToApi } from "../../hooks/useApi";
import { Icon, PaginatedScroll } from "../../components/reusable";

const ModTaskList = ({ game, authMethod, authToken }) => {
  const [approvedIds, setApprovedIds] = useState({});
  const [rejectedTasks, setRejectedTasks] = useState({});

  const resolve = (task, status) => {
    resolveModtask({
      taskId: task.id,
      GameId: game.id,
      status,
      authMethod,
      authToken,
    });
    status === "approved"
      ? setApprovedIds({ ...approvedIds, [task.referenceId]: true })
      : setRejectedTasks({ ...rejectedTasks, [task.id]: true });
  };

  return !authToken || !game || !game.id ? (
    <span />
  ) : (
    <span className="ModTaskList p-5">
      <h2 className="text-bold">Mod Que</h2>
      <PaginatedScroll
        endpointOptions={{ headers: { authorization: authToken, authMethod } }}
        endpoint={`/modtasks/${game.id}`}
        renderChildren={({ data }) => (
          <>
            {data &&
              data.map((task) =>
                approvedIds[task.referenceId] || rejectedTasks[task.id] ? (
                  <></>
                ) : (
                  <ModTaskItem task={task} resolve={resolve} key={task.id} />
                )
              )}
          </>
        )}
      />
    </span>
  );
};

export default ModTaskList;

const ModTaskItem = ({ task, resolve }) => {
  let { dataPrevious, dataAfter } = task;
  dataPrevious = dataPrevious || {};

  return (
    <div className="ModTaskItem flex mt-5">
      <Link to={`/video/${task.referenceId}`}>
        <img src={dataPrevious.thumbnailURL} alt="" className="h-56" />
      </Link>
      <div className="flex-column ml-5">
        <h2 className="text-bold">{task.type}</h2>
        <h3>
          {task.type === "match_update" ? (
            <Link to={`/video/${task.referenceId}`} className="link">
              {dataPrevious.name}
            </Link>
          ) : task.type === "player_update" ? (
            <Link
              to={`/player/${task.GameSlug}/${task.referenceSlug}`}
              className="link"
            >
              {task.referenceSlug}
            </Link>
          ) : (
            dataPrevious.name
          )}
        </h3>
        <DiffTable
          before={dataPrevious}
          after={dataAfter}
          GameSlug={task.GameSlug}
        />
        <div className="flex mt-5">
          <button
            className="success mr-5 icon-left"
            onClick={() => resolve(task, "approved")}
          >
            <Icon name="checkmark-outline" />
            APPROVE
          </button>
          <button
            className="danger icon-left"
            onClick={() => resolve(task, "rejected")}
          >
            <Icon name="trash-outline" />
            REJECT
          </button>
        </div>
      </div>
    </div>
  );
};

const DiffTable = ({ before, after, GameSlug }) => {
  const diffs = DIFFABLE_PROPS.filter(
    (i) => stringifyData(before[i]) !== stringifyData(after[i])
  );

  const b = {};
  const a = {};
  diffs.forEach((i) => {
    b[i] = stringifyData(before[i]);
    a[i] = stringifyData(after[i]);
  });

  const [gameClasses] = useApi(`/games/classes/${GameSlug}`, null, ["classes"]);

  return (
    <table className="DiffTable mt-5">
      <tbody>
        <tr>
          <th>Data</th>
          <th>Before</th>
          <th>After</th>
        </tr>
        {diffs &&
          diffs.map((i) => (
            <tr key={i}>
              <td>{strings.camelToTitle(i)}</td>
              <DiffCell obj={before} field={i} gameClasses={gameClasses} />
              <DiffCell obj={after} field={i} gameClasses={gameClasses} />
            </tr>
          ))}
      </tbody>
    </table>
  );
};

const DiffCell = ({ obj, field, gameClasses }) =>
  field === "teams" ? (
    <td>
      {strings.parseIfJSON(obj[field]).map((team, i) => (
        <span key={i}>
          {i > 0 && <b>VS</b>}
          <MatchTeam gameClasses={gameClasses} team={team} key={i} />
        </span>
      ))}
    </td>
  ) : field === "imageURL" ? (
    <td>
      <img alt="Modtask item" src={obj[field]} />
    </td>
  ) : (
    <td>{stringifyData(obj[field])}</td>
  );

const DIFFABLE_PROPS = [
  "name",
  "teams",
  "stage",
  "round",
  "playerData",
  "imageURL",
];

const stringifyData = (data) =>
  typeof data === "object" ? JSON.stringify(data) : data;

const resolveModtask = ({ taskId, GameId, status, authToken, authMethod }) =>
  postToApi(
    `/modtasks/resolve/${taskId}`,
    {
      status,
      GameId,
    },
    "POST",
    {
      Authorization: authToken,
      authMethod,
    }
  );

const MatchTeam = ({ team, gameClasses }) => (
  <>
    {Object.keys(team).map((playerName, pI) => (
      <span className="flex-middle" key={pI}>
        {pI > 0 && <span>&nbsp;|&nbsp;</span>}
        <div className="">{playerName}</div>
        :&nbsp;
        {team[playerName].length ? (
          team[playerName].map((gameClass, i) =>
            gameClasses && gameClasses.classes[gameClass] ? (
              <img
                className="h-5"
                src={gameClasses.classes[gameClass].iconURL}
                alt=""
                key={i}
                title={gameClass}
              />
            ) : gameClass ? (
              <i key={i}>
                {gameClass}
                {i < team[playerName].length - 1 && <span>,&nbsp;</span>}
              </i>
            ) : (
              <></>
            )
          )
        ) : (
          <span />
        )}
      </span>
    ))}
  </>
);
