import React, { useState } from "react";

import Icon from "../Icon/Icon";
import Input from "../Input/Input";
import "./ActivatedInput.scss";

const ActivatedInput = ({
  icon = "search-outline",
  iconStyle = {},
  onSubmit,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [query, setQuery] = useState("");

  const submit = () => {
    setIsOpen(false);
    onSubmit(query);
    setQuery("");
  };

  return (
    <div className="ActivatedInput">
      {isOpen ? (
        <div className="grow">
          <Input
            icon={icon}
            onBlur={() => setIsOpen(false)}
            type="text"
            onChange={(inputTxt) => setQuery(inputTxt)}
            onKeyDown={(e) => e.key === "Enter" && submit()}
            value={query}
            darkModeEnabled={false}
            autoFocus
            {...props}
          />
        </div>
      ) : (
        <div className="icon" style={iconStyle} onClick={() => setIsOpen(true)}>
          <Icon name={icon} className="cursor-pointer grey text-2xl" />
        </div>
      )}
    </div>
  );
};

export default ActivatedInput;
