import { cache } from '../helpers';

const FORCE_PROD = true;
const CLEAR_CACHE = false;
// ENABLE WHEN USING A DIFF DEVICE
const USE_LOCAL_NETWORK_API = false;

const LOCAL_IP = `192.168.87.70`;

const API_URLS = {
  production: `https://ggvods-api.onrender.com/api`,
  // production: `https://ggvods.herokuapp.com/api`,
  development: `http://${USE_LOCAL_NETWORK_API ? LOCAL_IP : 'localhost'}:3000/api`,
};

export const NODE_ENV = FORCE_PROD ? 'production' : process.env.NODE_ENV || 'development';
export const API_URL = API_URLS[NODE_ENV];

export const FIREBASE_CONFIG = {
  apiKey: 'AIzaSyDSjN_gjxlIrX0IV7vYbDm0kA87vxzK9DI',
  authDomain: 'ggvods-prod.firebaseapp.com',
  databaseURL: 'https://ggvods-prod.firebaseio.com',
  projectId: 'ggvods-prod',
  storageBucket: 'ggvods-prod.appspot.com',
  messagingSenderId: '205820046288',
  appId: '1:205820046288:web:69374a77d60ec1616627d5',
  measurementId: 'G-KR8GNWYNL0',
};

export const CLIENT_VERSION = 346;

export const IS_MOBILE = window.matchMedia('only screen and (max-width: 760px)').matches;

if (CLEAR_CACHE) {
  localStorage.clear();
}

export const IS_FIRST_VISIT = !cache.getCache('HAS_VISITED');
cache.setCache('HAS_VISITED', true);
