import { useContext } from "react";

import { AppContext } from "../context/AppContext";

const useModal = () => {
  const { setContext, modalContent } = useContext(AppContext);
  const setModal = (modalContent) => setContext({ modalContent });
  const closeModal = () => setModal(null);
  return { setModal, modalContent, closeModal };
};

export default useModal;
