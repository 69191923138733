export const setCache = (key, obj) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const getCache = (key) => {
  const item = localStorage.getItem(key);
  return item ? JSON.parse(item) : null;
};

export const clearCache = (key) => {
  localStorage.removeItem(key);
};

export const cacheGameIcons = (games = []) => {
  games.forEach(
    ({ slug, iconURL }) => iconURL && setCache(`game_icon_${slug}`, iconURL)
  );
};

export const getCachedGameIcon = (GameSlug) =>
  getCache(`game_icon_${GameSlug}`);

export default {
  getCache,
  setCache,
  clearCache,
  cacheGameIcons,
  getCachedGameIcon,
};
