import React from "react";

import { strings } from "../../../helpers";
import "./Select.scss";

const Select = ({ options = [], onChange, value }) => (
  <select value={value} onChange={(e) => onChange(e.target.value)}>
    {options.map((o) => (
      <option key={o} value={o}>
        {strings.slugToTitle(o)}
      </option>
    ))}
  </select>
);

export default Select;
