import React from "react";
import { Link, useHistory } from "react-router-dom";

import { analytics, cache, dates, strings } from "../../../helpers";
import { Icon } from "../index";
import "./EventCard.scss";

const EventCard = (props) => {
  let {
    isHidingSpoilers,
    cardIcon,
    event: {
      name = "",
      publishedAt,
      lastPublishedAt,
      numVideos,
      containsSpoilers,
      imageURL,
      duration,
      id,
      thumbnailURL,
      GameSlug,
      round,
      stage,
      iconURL,
      playlistPosition,
      tier,
    },
  } = props;
  const isEvent = !isNaN(numVideos);
  const shouldHideSpoilers = !isEvent && isHidingSpoilers && containsSpoilers;
  iconURL = iconURL || cache.getCachedGameIcon(GameSlug);
  let cardImg = imageURL || thumbnailURL || "";
  cardImg =
    cardImg === NO_IMG_URL || cardImg.includes(`http://`) ? null : cardImg;
  const spoilerProofTitle =
    stage === "Pools" ? stage : round || `Round ${playlistPosition + 1}`;
  const history = useHistory();

  return (
    <Link
      to={isEvent ? `/event/${GameSlug}/${id}` : `/video/${id}`}
      className="VideoCard EventCard dark border-radius mr-6 mb-5"
      onClick={() => analytics.save("event_card_click", { event: props.event })}
    >
      <div className={`cover-img`}>
        {shouldHideSpoilers ? (
          <>
            <div className="spoiler-guard cover w-1-1 border-radius-sm">
              {spoilerProofTitle}
              <br />
              ??? vs. ???
            </div>
            <img
              className={`cover blurred w-1-1 border-radius-sm`}
              src={cardImg}
              alt=""
            />
          </>
        ) : cardImg ? (
          <img className="cover w-1-1 border-radius-sm" src={cardImg} alt="" />
        ) : (
          <div
            className={`flex-centered h-1-1 bg-info border-radius-sm
             light-always text-xl text-center text-bold`}
          >
            {name}
          </div>
        )}
        {tier && tier !== "local" && (
          <div
            className={`tier white border-radius-sm py-px px-2 text-bold flex-middle
             ${tier === "supermajor" ? "bg-warning" : "bg-info"}`}
          >
            <Icon name="trophy" className="py-px mt-1 mr-1" />
            <div>{tier.toUpperCase()}</div>
          </div>
        )}
        {!shouldHideSpoilers && duration && (
          <div className="timebox border-radius-sm">
            {strings.formatVideoDuration(duration)}
          </div>
        )}
      </div>
      <div className="EventCard-details flex-between flex-middle py-1 px-3">
        <div className="EventCard-info flex-column flex-center">
          <div className="dark text-bold">
            {shouldHideSpoilers
              ? spoilerProofTitle
              : name && name.substring(0, 50)}
          </div>
          {isEvent && numVideos ? `${numVideos} videos - ` : ""}
          {dates.formatDate(isEvent ? lastPublishedAt : publishedAt)}
        </div>
        {(cardIcon || iconURL) && (
          <img
            src={cardIcon || iconURL}
            title="Go to game"
            className="h-8"
            alt=""
            onClick={(e) => {
              e.preventDefault();
              history.push(`/game/${GameSlug}`);
            }}
          />
        )}
      </div>
    </Link>
  );
};

export default EventCard;

const NO_IMG_URL = "https://i.ytimg.com/img/no_thumbnail.jpg";
