import React, { useContext } from "react";
import firebase from "@firebase/app";
import "@firebase/auth";

import { UserContext } from "../../../context/UserContext";
import { analytics } from "../../../helpers";
import { Icon } from "../../reusable";

const SocialLoginBtn = ({
  type,
  icon,
  backgroundColor,
  color = "white",
  onLogin,
  isNew,
}) => {
  const { socialLogin } = useContext(UserContext);
  return (
    <button
      className="SocialLoginBtn icon-left"
      style={{ backgroundColor, borderColor: backgroundColor, color }}
      onClick={() =>
        loginWithThirdParty(type, (err, authInfo) => {
          if (err && err.code === "auth/operation-not-allowed") {
            alert(`ERR: Enable ${icon} auth for firebase`);
            window.open(getAuthConfigUrl(), "_blank");
          } else if (err) {
            analytics.save("login_err", { provider: "google", error: err });
            onLogin(err);
          } else {
            onLogin(authInfo);
            socialLogin(authInfo);
          }
        })
      }
    >
      <Icon name={icon} />
      {isNew ? "CREATE " : "LOGIN "}WITH {type.toUpperCase()}
    </button>
  );
};

export default SocialLoginBtn;

const loginWithThirdParty = (type, callback) => {
  firebase
    .auth()
    .signInWithPopup(new firebase.auth[`${type}AuthProvider`]())
    .then((authInfo) => {
      callback(null, authInfo);
    })
    .catch((error) => {
      callback(error);
    });
};

const getAuthConfigUrl = () =>
  `https://console.firebase.google.com/u/0/project/${
    firebase.app().options.projectId
  }/authentication/providers`;
