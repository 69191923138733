import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

import useApi, { postToApi } from "../../../hooks/useApi";
import { analytics, strings } from "../../../helpers";
import { useModal } from "../../../hooks";
import { uploadDocument } from "../../reusable/Form/Form";
import { Input, Icon, Modal } from "../../../components/reusable";

const EditClassesModal = ({ game, authMethod, authToken }) => {
  const [fetchedClasses] = useApi(`/games/classes/${game.slug}`, null, [
    "classes",
  ]);
  const { closeModal } = useModal();
  const [error, setError] = useState();
  const [classDisplayName, setClassDisplayName] = useState("Class");
  const [gameClasses, setGameClasses] = useState();

  useEffect(() => {
    if (!fetchedClasses) return;
    setGameClasses(fetchedClasses.classes);
    fetchedClasses.classDisplayName &&
      setClassDisplayName(fetchedClasses.classDisplayName);
  }, [fetchedClasses]);

  const pluralClass = strings.pluralize(classDisplayName);
  return (
    <Modal showCloseBtn>
      <div className="EditClassesModal flex-column flex-middle p-5">
        <h2 className="text-center mb-5">{pluralClass}</h2>
        <div className="">
          <Input
            onChange={setClassDisplayName}
            value={classDisplayName}
            label="Class name ('Champion', 'Hero', 'Character' etc.)"
            containerClass="w-4-5"
          />
        </div>
        <GameClassEditor
          onChange={setGameClasses}
          value={gameClasses}
          plural={pluralClass}
          single={classDisplayName}
        />
        <div className=""></div>

        <span className="mt-8 pt-8">
          {error && <div className="danger">{error}</div>}
          <button
            className={`success`}
            disabled={!gameClasses}
            onClick={async () => {
              const classesWithImgUrls = await bulkUploadFiles(
                gameClasses,
                `games/${game.slug}/class-icons/`
              );
              const postData = {
                classes: classesWithImgUrls,
                classDisplayName,
                GameSlug: game.slug,
                GameId: game.id,
              };
              const res = await postToApi(
                `/games/updateClasses`,
                postData,
                "POST",
                {
                  authorization: authToken,
                  authMethod,
                }
              );
              if (res.errors) {
                analytics.save(`add_classes_game_error`, {
                  postData,
                  error: res.errors,
                });
                return setError(res.errors[0].message);
              }
              analytics.save(`add_classes_to_game`, { postData, res });
              window.open(`/game/${game.slug}`, "_self");
              closeModal();
            }}
          >
            <div>SAVE {pluralClass.toUpperCase()}</div>
          </button>
          <button className="danger ml-2" onClick={closeModal}>
            {"CANCEL"}
          </button>
        </span>
      </div>
    </Modal>
  );
};

export default EditClassesModal;

const GameClassEditor = ({
  onChange,
  value,
  plural = "Classes",
  single = "Class",
}) => {
  const [name, setName] = useState(null);
  const [aliases, setAliases] = useState("");
  const [image, setImage] = useState(null);
  useEffect(() => {
    if (image && !name && name !== "") {
      const fn = image.file.name;
      setName(prettifyFileName(fn));
    }
  }, [image, name]);

  return (
    <div className="GameClassEditor mt-5">
      <div className="mb-5">{plural || "Classes"}:</div>
      {value &&
        Object.keys(value)
          .sort()
          .map((gameClass, i) => {
            if (!value[gameClass]) return <span key={i} />;
            const { iconURL, imgSrc } = value[gameClass];
            return (
              <img
                key={gameClass}
                title={gameClass}
                src={iconURL || imgSrc}
                onClick={() => {
                  const classObj = value[gameClass];
                  onChange({ ...value, [gameClass]: null });
                  setName(gameClass);
                  setImage(classObj);
                  setAliases(classObj.aliases);
                }}
                className="h-8 y-8"
                alt=""
              />
            );
          })}
      <div className="flex-start flex-middle my-5">
        <ImageInput
          onChange={(d) => setImage({ ...image, ...d })}
          value={image}
        />
        <div className="flex-column flex-start ml-5">
          <Input
            containerClass="w-4-5"
            label="Name"
            onChange={(n) => setName(n)}
            value={name}
          />
        </div>
        <div className="flex-column flex-start ml-5">
          <Input
            containerClass="w-4-5"
            label="Aliases (optional)"
            onChange={(a) => setAliases(a)}
            value={aliases}
          />
        </div>
      </div>
      <button
        disabled={!image || !name}
        onClick={() => {
          setImage(null);
          setName(null);
          setAliases("");
          delete image.aliases;
          onChange({
            ...value,
            [name]: { ...image, ...(aliases ? { aliases } : {}) },
          });
        }}
        className="mb-5"
      >
        ADD {single.toUpperCase()}
      </button>
      <FolderDropzone
        onChange={(files) => {
          const newClasses = {};
          files.forEach((file) => {
            newClasses[prettifyFileName(file.name)] = {
              imgSrc: URL.createObjectURL(file),
              file,
            };
          });
          onChange({ ...value, ...newClasses });
        }}
      />
    </div>
  );
};

// TODO: make its own component
const ImageInput = ({ onChange, value = {} }) => {
  const { file, imgSrc, iconURL } = value || {};
  const ref = useRef();
  const InputEl = ({ showBg = false }) => (
    <>
      {showBg && (
        <div className="image-upload bg-light flex-center flex-middle cursor-pointer">
          <Icon name={"image-outline"} />
        </div>
      )}
      <input
        type="file"
        ref={ref}
        onChange={() => onChange({ file: ref.current.files[0] })}
        style={{ display: "none" }}
      />
    </>
  );

  const [isNew, setIsNew] = useState(true);
  useEffect(() => {
    !value && setIsNew(true);
  }, [value]);

  let reader = new FileReader();
  reader.addEventListener(
    "load",
    () => {
      isNew && onChange({ imgSrc: reader.result, iconURL: null });
      reader.result && setIsNew(false);
    },
    false
  );

  file && reader.readAsDataURL(file);
  return (
    <div className="Form">
      <div className="FormField">
        <span className="ImageInput">
          Icon
          {value ? (
            <label>
              <img src={iconURL || imgSrc} alt="" />
              <InputEl />
            </label>
          ) : (
            <label>
              <InputEl showBg />
            </label>
          )}
        </span>
      </div>
    </div>
  );
};

const bulkUploadFiles = async (files, rootPath) => {
  const uploadTask = (imageObj, filePath) =>
    new Promise((resolve, reject) => {
      imageObj.iconURL
        ? resolve(imageObj.iconURL)
        : uploadDocument(
            imageObj.file,
            filePath,
            (err, url) => (err ? reject(err) : resolve(url)),
            true
          );
    });
  for (let key in files) if (!files[key]) delete files[key];

  const fileKeys = Object.keys(files);
  const promises =
    fileKeys &&
    fileKeys.map((key) =>
      uploadTask(files[key], `${rootPath}/${strings.camelCase(key)}`)
    );
  return Promise.all(promises).then((urlArr) => {
    urlArr.forEach((url, i) => {
      const file = files[fileKeys[i]];
      file.iconURL = url;
      delete file.file;
      delete file.imgSrc;
    });
    return files;
  });
};

const FolderDropzone = ({ onChange }) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone();

  const status = useMemo(() => (isDragActive ? "zone-active" : ""), [
    isDragActive,
  ]);

  useEffect(() => {
    onChange(acceptedFiles);
    // eslint-disable-next-line
  }, [acceptedFiles]);

  return (
    <section className="FolderDropzone">
      <div {...getRootProps({ className: `dropzone ${status}` })}>
        <input {...getInputProps()} />
        <div className="flex-column flex-middle clex-center">
          Bulk Import
          <Icon name="cloud-upload-outline" className="my-2" />
          <div className="flex">Drag and drop a folder of images</div>
        </div>
      </div>
    </section>
  );
};

const prettifyFileName = (str) => {
  let fileName = str.substring(0, str.indexOf(".")).replace(/[-_]/, " ");
  return strings.camelToTitle(strings.camelCase(fileName));
};
