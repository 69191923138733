import React, { useRef } from "react";
import useOnClickOutside from "use-onclickoutside";

import { useModal } from "../../../hooks";
import "./Modal.scss";

const Modal = ({
  onClose,
  isVisible = true,
  showCloseBtn = true,
  closeOnClickOutside = true,
  children,
  className = "",
  contentClass = "",
  containerClass = "",
  animation = "slide-up",
}) => {
  const { closeModal } = useModal();
  onClose = onClose || closeModal;

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  return isVisible ? (
    <div className={`Modal-container ${containerClass}`}>
      <div
        ref={ref}
        className={`Modal bg-lighter dark ${
          animation ? `animated ${animation}` : ""
        } ${className}`}
      >
        {showCloseBtn && (
          <div className="Modal-close flex-end">
            <span onClick={onClose}>&times;</span>
          </div>
        )}
        <div className={`content bg-lighter ${contentClass || ""}`}>
          {children}
        </div>
      </div>
      <div className={`OnClickOutside ${animation ? "" : "no-animation"}`} />
    </div>
  ) : null;
};

export default Modal;
