const camelCase = (str) => {
  if (str.includes(" ")) {
    str = str
      .toLowerCase()
      .replace(/[^A-Za-z0-9]/g, " ")
      .split(" ")
      .reduce((result, word) => result + capitalize(word.toLowerCase()));
  }
  return str.charAt(0).toLowerCase() + str.slice(1);
};

const camelToTitle = (camelCase) =>
  camelCase
    .replace(/([A-Z]|[^a-z])/g, (match) => ` ${match}`)
    .replace(/^./, (match) => match.toUpperCase());

const slugToTitle = (slugCase) =>
  slugCase
    .replace(/([-])/g, (match) => ` ${match.substring(1)}`)
    .replace(/^[a-z]|(\s[a-z])/g, (match) => match.toUpperCase());

const capitalize = (str = "") =>
  str ? str.charAt(0).toUpperCase() + str.toLowerCase().slice(1) : "";

const formatVideoDuration = (numSeconds) => {
  const mins = Math.floor(numSeconds / 60);
  let seconds = Math.round(numSeconds % 60);
  seconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${mins}:${seconds}`;
};

const URL_REGEX =
  /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const pluralize = function (initialString = "", revert) {
  const str = initialString.slice(0);
  let plural = {
    "(quiz)$": "$1zes",
    "^(ox)$": "$1en",
    "([m|l])ouse$": "$1ice",
    "(matr|vert|ind)ix|ex$": "$1ices",
    "(x|ch|ss|sh)$": "$1es",
    "([^aeiouy]|qu)y$": "$1ies",
    "(hive)$": "$1s",
    "(?:([^f])fe|([lr])f)$": "$1$2ves",
    "(shea|lea|loa|thie)f$": "$1ves",
    sis$: "ses",
    "([ti])um$": "$1a",
    "(tomat|potat|ech|her|vet)o$": "$1oes",
    "(bu)s$": "$1ses",
    "(alias)$": "$1es",
    "(octop)us$": "$1i",
    "(ax|test)is$": "$1es",
    "(us)$": "$1es",
    "([^s]+)$": "$1s",
  };
  let singular = {
    "(quiz)zes$": "$1",
    "(matr)ices$": "$1ix",
    "(vert|ind)ices$": "$1ex",
    "^(ox)en$": "$1",
    "(alias)es$": "$1",
    "(octop|vir)i$": "$1us",
    "(cris|ax|test)es$": "$1is",
    "(shoe)s$": "$1",
    "(o)es$": "$1",
    "(bus)es$": "$1",
    "([m|l])ice$": "$1ouse",
    "(x|ch|ss|sh)es$": "$1",
    "(m)ovies$": "$1ovie",
    "(s)eries$": "$1eries",
    "([^aeiouy]|qu)ies$": "$1y",
    "([lr])ves$": "$1f",
    "(tive)s$": "$1",
    "(hive)s$": "$1",
    "(li|wi|kni)ves$": "$1fe",
    "(shea|loa|lea|thie)ves$": "$1f",
    "(^analy)ses$": "$1sis",
    "((a)naly|(b)a|(d)iagno|(p)arenthe|(p)rogno|(s)ynop|(t)he)ses$": "$1$2sis",
    "([ti])a$": "$1um",
    "(n)ews$": "$1ews",
    "(h|bl)ouses$": "$1ouse",
    "(corpse)s$": "$1",
    "(us)es$": "$1",
    s$: "",
  };
  let irregular = {
    move: "moves",
    foot: "feet",
    goose: "geese",
    sex: "sexes",
    child: "children",
    man: "men",
    tooth: "teeth",
    person: "people",
  };
  let uncountable = [
    "sheep",
    "fish",
    "deer",
    "moose",
    "series",
    "species",
    "money",
    "rice",
    "information",
    "equipment",
  ];
  // save some time in the case that singular and plural are the same
  if (uncountable.indexOf(str.toLowerCase()) >= 0) return this;
  // check for irregular forms
  var pattern, replace;
  for (let word in irregular) {
    if (revert) {
      pattern = new RegExp(irregular[word] + "$", "i");
      replace = word;
    } else {
      pattern = new RegExp(word + "$", "i");
      replace = irregular[word];
    }
    if (pattern.test(str)) return str.replace(pattern, replace);
  }
  let array = revert ? singular : plural;
  // check for matches using regular expressions
  for (let reg in array) {
    let pattern = new RegExp(reg, "i");
    if (pattern.test(str)) return str.replace(pattern, array[reg]);
  }
  return str;
};

const slugify = (initialString) => {
  let str = "" + initialString;
  str = str.replace(/^\s+|\s+$/g, "").toLowerCase();
  const from =
    "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  const to =
    "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }
  return str
    .replace(/[^a-z0-9 -]/g, "")
    .replace(/\s+/g, "-")
    .replace(/-+/g, "-");
};

const parseIfJSON = (stringOrObject) =>
  typeof stringOrObject === "string"
    ? JSON.parse(stringOrObject)
    : stringOrObject;

export default {
  camelCase,
  camelToTitle,
  capitalize,
  formatVideoDuration,
  parseIfJSON,
  pluralize,
  slugToTitle,
  slugify,
  URL_REGEX,
};
