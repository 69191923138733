import { useEffect, useState } from "react";
import firebase from "@firebase/app";
import "@firebase/database";

import { cache } from "../helpers";

const useReadDb = (path, defaultContent = "") => {
  const lastFromCache = cache.getCache(path);
  const [data, setData] = useState(lastFromCache || defaultContent);
  useEffect(() => {
    if (!path) return;
    contentCache[path]
      ? setData(contentCache[path])
      : firebase
          .database()
          .ref(path)
          .once("value")
          .then((snap) => {
            const val = snap.val();
            contentCache[path] = val;
            setData(val);
            cache.setCache(path, val);
          });
  }, [path]);

  return data;
};

export default useReadDb;

const contentCache = {};
