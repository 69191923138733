import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { IS_MOBILE } from "../../config/config";
import {
  PaginatedScroll,
  Screen,
  VideoSection,
} from "../../components/reusable";
import "./HomeFeed.scss";

const HomeFeed = () => {
  const { user } = useContext(UserContext);
  const followedGames = (user && user.followedGames) || [];
  const endpoint = `/events/home-feed?followedGames=${Object.keys(
    followedGames
  ).toString()}`;
  return (
    <Screen
      className="HomeFeed content"
      renderChildren={({ scrollRef }) => (
        <>
          <PaginatedScroll
            threshold={0}
            getScrollParent={() => scrollRef.current}
            endpoint={endpoint}
            renderChildren={({ data, total, isLoading }) => {
              followedGames &&
                data.forEach((event) => {
                  const game = followedGames[event.GameSlug];
                  event.iconURL = game && game.iconURL;
                  return game;
                });
              return data && user !== undefined ? (
                data.map(({ label, labelNote, data, cardType }) =>
                  cardType === "portrait" ? (
                    <PortraitCards
                      key={label}
                      label={label}
                      labelNote={labelNote}
                      data={data}
                    />
                  ) : (
                    <span key={label}>
                      <VideoSection header={label} events={data} />
                      {label === "Last Week's Events" && (
                        <>
                          <div className="Divider mb-5 ml-5"></div>
                          <div className="pb-2"></div>
                        </>
                      )}
                    </span>
                  )
                )
              ) : (
                <></>
              );
            }}
          />
        </>
      )}
    />
  );
};

export default HomeFeed;

const PortraitCards = ({ label, labelNote, data }) =>
  IS_MOBILE ? (
    <span />
  ) : (
    <div className="PortraitCards m-2">
      <div className="mb-5 flex-bottom">
        <h2>{label}</h2>
        {labelNote && <h4 className="primary pb-1 ml-3">{labelNote}</h4>}
      </div>
      <div className="flex-wrap">
        {data.map(({ name, portraitURL, slug }) => (
          <div className="PortraitCard mr-5 mb-5" key={slug}>
            <Link to={`/game/${slug}`}>
              <img className={`cover-img`} src={portraitURL} alt="game-cover" />
              <h3 className="PortraitCard-name dark pl-2">{name}</h3>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
