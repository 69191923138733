import React, { useContext } from "react";

import { UserContext } from "../../../context/UserContext";
import { FollowedGamesList } from "..";
import RECOMMENDED_GAMES from "./recommended_games.json";

const RecommendedGamesList = () => {
  const { user } = useContext(UserContext);
  const followedGames = user
    ? user.followedGames || {}
    : user === undefined // user not established yet (initial pg load)
    ? null
    : {};

  // returns 4 games not already followed
  const recommendedGames = [];
  for (let i = 0; followedGames && i < RECOMMENDED_GAMES.length; i++) {
    const game = RECOMMENDED_GAMES[i];
    if (followedGames[game.slug]) continue;
    if (recommendedGames.push(game) === 4) break;
  }

  return followedGames && recommendedGames ? (
    <FollowedGamesList
      header="Recommended"
      searchButton={false}
      games={recommendedGames}
    />
  ) : (
    <span />
  );
};

export default RecommendedGamesList;
