import React, { useEffect, useState } from "react";

import Icon from "../Icon/Icon";
import "./TabNav.scss";

const TabNav = ({
  options = [],
  icons = [],
  activeTab,
  onClick,
  initialFocus = 0,
}) => {
  const [focusIndex, setFocusIndex] = useState(initialFocus);
  useEffect(() => {
    activeTab && setFocusIndex(options.indexOf(activeTab));
  }, [activeTab, options]);

  return (
    <div className="TabNav flex-row ml-8">
      {options.map((o, i) => (
        <div
          key={o}
          onClick={() => {
            setFocusIndex(i);
            onClick(o);
          }}
          className={`tab-option px-2 flex-middle flex-center
          ${focusIndex === i ? "focused" : ""}`}
        >
          {icons[i] && <Icon name={icons[i]} />}
          <p className="non-highlightable">{o}</p>
        </div>
      ))}
    </div>
  );
};
export default TabNav;
