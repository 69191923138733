import React from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { analytics } from "../../helpers";
import { postToApi } from "../../hooks/useApi";
import { Icon } from "../../components/reusable";

const UnsubscribeScreen = () => {
  const { uid } = useParams();
  const history = useHistory();

  return (
    <div className="UnsubscribeScreen pl-24">
      Are you sure you want to unsubscribe from all emails?
      <br />
      <br /> You could also change your email preferences to receive them less
      frequently.
      <br />
      <div className="flex mt-8">
        <button
          className="mr-4 icon-left"
          onClick={() => history.push(`/?modal=notifications`)}
        >
          <Icon name="cog" />
          Change Preferences
        </button>
        <button
          className="warning"
          onClick={() => {
            analytics.save("create_account", { provider: "email" });
            alert(`You'll never hear from us again 😥`);
            postToApi(`/users/unsubscribe`, { uid });
            history.push(`/`);
          }}
        >
          Unsubscribe
        </button>
      </div>
    </div>
  );
};

export default UnsubscribeScreen;
