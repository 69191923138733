import React, { useRef } from "react";

import SideMenu from "../../../components/SideMenu/SideMenu";
import "./Screen.scss";

const Screen = ({
  showSideMenu = true,
  children,
  renderChildren,
  className,
  ...props
}) => {
  const scrollRef = useRef();
  return (
    <div className="Screen" {...props}>
      {showSideMenu ? (
        <>
          <SideMenu />
          <div className="content bg-screen dark" id={`Screen-content`}>
            <div className="feed scrollbar force-overflow">
              <div className={className} ref={scrollRef}>
                {renderChildren ? renderChildren({ scrollRef }) : children}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div
          className="content bg-screen dark no-side-menu"
          id={`Screen-content`}
        >
          <div className="feed scrollbar force-overflow">
            <div className={className} ref={scrollRef}>
              {renderChildren ? renderChildren({ scrollRef }) : children}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Screen;
