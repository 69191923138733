import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { Screen, TabNav } from "../../reusable";
import "./BannerNavScreen.scss";

const BannerNavScreen = ({
  bannerImage,
  navOptions = {},
  navIcons = [],
  renderNavLeft,
  renderNavRight,
  hideOptions = false,
  name,
  icon,
  iconLink,
  iconTitle,
  onChange,
  className,
}) => {
  const history = useHistory();
  const location = useLocation();
  let query = useQuery();
  const navKeys = Object.keys(navOptions);

  const urlTabParam = query.get("tab");
  const [tab, setT] = useState(urlTabParam || navKeys[0]);
  const setTab = (nextTab) => {
    const isRootTab = !nextTab || nextTab === navKeys[0];
    nextTab = nextTab || navKeys[0];
    setT(nextTab);
    if (nextTab === urlTabParam || (isRootTab && urlTabParam === null)) return;
    history.push(`${location.pathname}${isRootTab ? `` : `?tab=${nextTab}`}`);
  };

  useEffect(() => {
    if (urlTabParam === tab) return;
    setTab(urlTabParam);
    onChange && onChange(urlTabParam || navKeys[0]);
    // eslint-disable-next-line
  }, [urlTabParam]);

  const GameIcon = <img src={icon} alt="" className="h-12" />;

  return (
    <Screen
      className={`BannerNavScreen content ${className}`}
      renderChildren={({ scrollRef }) => (
        <>
          {bannerImage && (
            <img src={bannerImage} alt="" className="banner w-1-1" />
          )}
          <div className="StickyTop sticky-nav flex-between bg-white px-5 h-16">
            <div className="nav-left flex-middle">
              {(name || icon) && (
                <div className="flex-middle sticky-name">
                  {icon &&
                    (iconLink ? (
                      <Link to={iconLink} title={iconTitle} className="pt-1">
                        {GameIcon}
                      </Link>
                    ) : (
                      GameIcon
                    ))}
                  {name && <h3 className="ml-5 ">{name}</h3>}
                </div>
              )}
              {renderNavLeft && renderNavLeft()}
              {!hideOptions && (
                <TabNav
                  options={navKeys}
                  icons={navIcons}
                  onClick={(tab) => {
                    history.push(
                      `${location.pathname}${
                        tab === navKeys[0] ? "" : `?tab=${tab}`
                      }`
                    );
                    onChange && onChange(tab);
                  }}
                  activeTab={tab}
                />
              )}
            </div>
            <div className="nav-right flex-middle">
              {renderNavRight && renderNavRight()}
            </div>
          </div>
          <div className="BannerNavScreen-content pl-8 pt-2">
            {navOptions[tab] && navOptions[tab]({ scrollRef })}
          </div>
        </>
      )}
    />
  );
};

export default BannerNavScreen;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
