import React, { Component, createContext } from "react";
import firebase from "@firebase/app";
import "@firebase/database";
import "@firebase/auth";

import { cache } from "../helpers";
import {
  deleteCachedWatchHistory,
  portCachedHistoryToDb,
} from "../hooks/useWatchHistory";

const LOG_USER = false;

const UserContext = createContext({});

class UserContextProvider extends Component {
  state = {
    user: undefined,
    publicInfo: {},
    onLoginEvents: [],
    authToken: null,
    role: "user",
  };

  componentDidMount() {
    listenToUser((err, user) => {
      err && console.log("err fetching user:", err);
      LOG_USER && console.log("user db data:", user);
      const { privateInfo, serverInfo, publicInfo, watchHistory } = user || {};
      if (user && !this.state.user) {
        // user logs in, override cache with db data if exists
        user.watchHistory && cache.setCache(`watch-history`, user.watchHistory);
        // set initial db hist from cache
        !user.watchHistory &&
          cache.getCache(`watch-history`) &&
          portCachedHistoryToDb();

        firebase
          .auth()
          .currentUser.getIdToken(/* forceRefresh */ true)
          .then((authToken) => {
            cache.setCache("HAS_CREATED_ACCOUNT", true);
            this.state.onLoginEvents.forEach(
              (ev) =>
                typeof ev === "function" &&
                ev({
                  privateInfo,
                  serverInfo,
                  authToken,
                  watchHistory,
                })
            );
            this.setState({ authToken });
          });
      }
      this.setState({
        user: user ? privateInfo : null,
        role: serverInfo ? serverInfo.role : "user",
        publicInfo,
      });
    });
  }

  login = (email, password) =>
    loginWithEmailOnFirebase(email, password).then((user) => {
      saveUser(user);
      return user;
    });

  onLogin = (callback) =>
    this.setState({ onLoginEvents: [...this.state.onLoginEvents, callback] });

  logout = () => {
    deleteCachedWatchHistory();
    // AUTH FLOW
    logoutOfFirebase();
    this.setState({ user: null });
  };

  createUser = (email, password) => {
    return createUserWithEmailOnFirebase(email, password).then((user) => {
      LOG_USER && console.log("created user!", user);
      return user;
    });
  };

  updateUser = (newData, updatePath) => {
    if (!this.state.user) return console.error("NO USER, CANT UPDATE");
    updateUserOnFirebase(this.state.user.uid, newData, updatePath);
  };

  socialLogin = (authInfo) => saveUser(authInfo.user);

  followGame = (game, shouldUnfollow = false, uid) => {
    return addFollowedGameOnFirebase(
      uid || this.state.user.uid,
      game,
      shouldUnfollow
    );
  };

  determineAuthMethod = (game = {}, event = {}) => {
    const { user, role } = this.state;
    if (!user || !role) return null;
    return role === "superadmin"
      ? "superadmin"
      : game && game.admins && JSON.parse(game.admins)[user.uid]
      ? "gameAdmin"
      : event && event.admins && JSON.parse(event.admins)[user.uid]
      ? "eventAdmin"
      : null;
  };

  render() {
    const { user, authToken, role } = this.state;

    return (
      <UserContext.Provider
        value={{
          state: this.state,
          user,
          authToken,
          role,
          setContext: (state) => this.setState(state),
          login: this.login,
          onLogin: this.onLogin,
          socialLogin: this.socialLogin,
          logout: this.logout,
          createUser: this.createUser,
          updateUser: this.updateUser,
          followGame: this.followGame,
          determineAuthMethod: this.determineAuthMethod,
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export { UserContextProvider, UserContext };

const createUserWithEmailOnFirebase = (email, password) => {
  if (!email || !password) {
    throw new Error(`Requires an email && password`);
  }
  return firebase
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      saveUser(res.user);
      return res.user;
    });
};

const loginWithEmailOnFirebase = (email, password) => {
  const auth = firebase.auth();
  return auth
    .signInWithEmailAndPassword(email, password)
    .then((res) => res.user);
};

const listenToUser = (callback) => {
  firebase.auth().onAuthStateChanged((userAuth) => {
    LOG_USER && console.log("userAuth:", userAuth);
    userAuth
      ? listenToUserData(userAuth.uid, callback)
      : callback(null, userAuth);
  });
};

const updateUserOnFirebase = (uid, userData, updatePath) =>
  firebase.database().ref(`/users/${uid}${updatePath}`).update(userData);

const logoutOfFirebase = () => firebase.auth().signOut();

const listenToUserData = (uid, callback) =>
  firebase
    .database()
    .ref(`/users/${uid}`)
    .on("value", (snap) => callback(null, snap.val()));

const saveUser = (loginInfo) => {
  const {
    uid,
    email,
    photoURL,
    metadata,
    isAnonymous,
    emailVerified,
    displayName,
    refreshToken,
    phoneNumber,
    tenantId,
    providerData,
  } = loginInfo;
  const saveData = {
    uid,
    email,
    photoURL,
    loginInfo: {
      metadata,
      isAnonymous,
      emailVerified,
      displayName,
      refreshToken,
      phoneNumber,
      tenantId,
      providerData,
    },
  };

  return saveToDb(`/users/${uid}/privateInfo`, saveData).then(() => saveData);
};

const addFollowedGameOnFirebase = (uid, game, shouldUnfollow) => {
  const { shortname, iconURL } = game;
  saveToDb(`/followersByGame/${game.slug}`, {
    [uid]: shouldUnfollow ? null : true,
  });
  return saveToDb(`/users/${uid}/privateInfo/followedGames`, {
    [game.slug]: shouldUnfollow
      ? null
      : !shortname && !iconURL
      ? { EMPTY_GAME_DATA: true }
      : { iconURL, shortname },
  });
};

const saveToDb = (path, data) => firebase.database().ref(path).update(data);
