import React, { useState } from "react";
import firebase from "@firebase/app";
import { useHistory } from "react-router-dom";

import { analytics, strings } from "../../../helpers";
import { useModal } from "../../../hooks";
import { postToApi } from "../../../hooks/useApi";
import { uploadDocumentSync } from "../../reusable/Form/Form";
import { Form, FormDivider, Icon, Modal } from "../../../components/reusable";
import { AddChannelsModal, EditClassesModal, LoginModal } from "../";

const CreateGameModal = ({ game, authMethod, authToken }) => {
  const { closeModal, setModal } = useModal();
  const [error, setError] = useState();
  const history = useHistory();
  return (
    <Modal showCloseBtn>
      <Form
        title={game ? "Edit Game" : "New Game"}
        defaultValues={game || {}}
        fields={{
          name: "string",
          shortname: "string",
          iconURL: "image",
          bannerURL: "image",
        }}
        labels={{
          iconURL: "Icon",
          bannerURL: "Banner Image",
        }}
        shouldUploadFiles={false}
        onSubmit={async (formData) => {
          const reqType = game ? "update" : "create";
          const currentUser = firebase.auth().currentUser;
          const execPostRequest = async (uid) => {
            const postData = {
              ...game,
              ...formData,
              admins: {
                [uid]: {
                  rank: "creator",
                  addedAt: new Date().getTime(),
                },
              },
            };
            const sluggedName = strings.slugify(postData.name);
            // wont upload if already a url
            postData.iconURL = await uploadDocumentSync(
              postData.iconURL,
              `/games/${sluggedName}/branding/icon`
            );
            postData.bannerURL = await uploadDocumentSync(
              postData.bannerURL,
              `/games/${sluggedName}/branding/banner`
            );

            if (game) delete postData.admins;
            debugger;
            const res = await postToApi(`/games/${reqType}`, postData, "POST", {
              authorization: authToken,
              authMethod,
            });
            debugger;
            if (res.errors) {
              analytics.save(`${reqType}_game_error`, {
                postData,
                error: res.errors,
              });
              return setError(
                res.errors[0].type === "unique violation" ? (
                  <span
                    className="link"
                    onClick={() => {
                      history.push(`/game/${res.errors[0].value}`);
                      closeModal();
                    }}
                  >
                    <span className="danger">Game already exists</span>
                  </span>
                ) : (
                  res.errors[0].message
                )
              );
            }
            analytics.save(`${reqType}_game`, { postData, res });
            game
              ? window.location.reload(false)
              : history.push(`/game/${res.slug}`);
            closeModal();
          };
          currentUser
            ? execPostRequest(currentUser.uid)
            : setModal(
                <LoginModal onLogin={(user) => execPostRequest(user.uid)} />
              );
        }}
        onCancel={closeModal}
      />
      {error && <div className="danger flex-center mt-8">{error}</div>}
      {game && (
        <>
          <div className="flex-center my-2">
            <FormDivider>OR</FormDivider>
          </div>
          <div className="flex-center flex-column flex-middle">
            <button
              title="Configure Champions, Heroes, Characters, etc."
              className="success w-4-5 icon-left"
              onClick={() =>
                setModal(
                  <EditClassesModal
                    game={game}
                    authMethod={authMethod}
                    authToken={authToken}
                  />
                )
              }
            >
              <Icon name="add-circle-outline" />
              ADD CLASSES
            </button>
            <button
              title="Add Channels to Automatically Import Tourneys"
              className="primary w-4-5 icon-left mt-2"
              onClick={() =>
                setModal(
                  <AddChannelsModal
                    game={game}
                    authMethod={authMethod}
                    authToken={authToken}
                  />
                )
              }
            >
              <Icon name="add-circle-outline" />
              ADD CHANNELS
            </button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateGameModal;
