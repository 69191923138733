import React, { useEffect, useRef, useState } from "react";
import useOnClickOutside from "use-onclickoutside";

import { useDebounce } from "../../../hooks";
import Icon from "../Icon/Icon";
import "./Input.scss";

const Input = ({
  icon,
  containerClass,
  autoFocus,
  onChange,
  isTextArea = false,
  children,
  label,
  onClickOutside,
  debounce = 0,
  value,
  showSpinner,
  disabled,
  onSubmit,
  darkModeEnabled = true,
  ...props
}) => {
  const inputRef = useRef();
  const [query, setQuery] = useState("");
  const [isFocused, setIsFocused] = useState(autoFocus);
  useDebounce(query, onChange, debounce);
  const inputValue = value || value === "" ? value : query;
  const inputProps = {
    ref: inputRef,
    value: inputValue,
    onChange: (e) => {
      !debounce && onChange && onChange(e.target.value);
      setQuery(e.target.value);
    },
    autoFocus,
    disabled,
    onKeyPress: onSubmit ? (e) => e.key === "Enter" && onSubmit() : null,
    ...props,
  };

  const containerRef = useRef();
  useOnClickOutside(containerRef, () => {
    if (isFocused) {
      onClickOutside && onClickOutside();
      // document.getElementById("Navbar").focus();
      setIsFocused(false);
    }
  });

  useEffect(() => {
    setIsFocused(autoFocus);
    autoFocus && inputRef.current.focus();
  }, [autoFocus]);

  return (
    <>
      {label && <div className={`${disabled ? "info " : ""}py-2`}>{label}</div>}
      <div
        ref={isFocused ? containerRef : null}
        className={`Input ${containerClass || ""} ${
          isFocused ? " focused" : ""
        }${isTextArea ? " multiline" : " singleline"}${
          disabled ? " disabled" : ""
        }${darkModeEnabled ? " dark-mode" : ""}
      `}
        onClick={
          !disabled
            ? () => {
                setIsFocused(true);
                inputRef.current.focus();
              }
            : null
        }
      >
        {icon && <Icon name={icon} />}
        {isTextArea ? (
          <textarea {...inputProps} rows={10} />
        ) : (
          <input {...inputProps} />
        )}
        {children}
        {isFocused && showSpinner && inputValue && <Icon name="spinner" />}
      </div>
    </>
  );
};

export default Input;
