import React from "react";

import EventCard from "../EventCard/EventCard";
import "./VideoSection.scss";

const VideoSection = ({ header, events = [], isHidingSpoilers, cardIcon }) => (
  <div className="VideoSection mb-8">
    <h2 className="mb-5">{header}</h2>
    <div className="videos flex-wrap">
      {events && events.length
        ? events.map((event, i) => (
            <EventCard
              key={event.id}
              event={event}
              index={i}
              cardIcon={cardIcon}
              isHidingSpoilers={isHidingSpoilers}
            />
          ))
        : "Loading.."}
    </div>
  </div>
);

export default VideoSection;
