import React, { memo, useContext, useEffect, useMemo, useState } from "react";

import { analytics, strings } from "../../../helpers";
import { useApi, useModal } from "../../../hooks";
import { postToApi } from "../../../hooks/useApi";
import { UserContext } from "../../../context/UserContext";
import { ClassSearchInput, Input, Modal } from "../../reusable";

const EditMatchModal = memo(({ match }) => {
  const [error, setError] = useState(null);
  const { closeModal } = useModal();
  const [classData, setClassData] = useState(null);
  const [matchName, setMatchName] = useState(match.name);
  const [event] = useApi(`/events/${match.EventId}`);
  const [game] = useApi(`/games/${match.GameSlug}`);
  const { authToken, determineAuthMethod, user } = useContext(UserContext);
  let authMethod = useMemo(
    () => (game && event ? determineAuthMethod(game, event) : null),
    // eslint-disable-next-line
    [game, event, authToken, determineAuthMethod]
  );

  return (
    <Modal>
      <div className="EditMatchModal">
        {authMethod && (
          <>
            <h2 className="text-center">Edit Match</h2>
            <Input
              label="Name"
              containerClass="mb-5 w-11-12"
              placeholder={match.name}
              onChange={(n) => setMatchName(n)}
              value={matchName}
            />
          </>
        )}
        <PlayerClassTagger match={match} onChange={(d) => setClassData(d)} />
        <div className="my-8" />
        <div className="mt-8 flex-center flex-row">
          <button
            className="success w-1-2"
            onClick={() => {
              authMethod = determineAuthMethod(game, event);
              const postMatchUpdate = async ({ authToken: token }) => {
                let postData = {
                  ...match,
                  name: matchName,
                  participants: [],
                  classes: [],
                  teams: classData,
                };
                Object.keys(classData).forEach((teamIndex) => {
                  const team = classData[teamIndex];
                  Object.keys(team).forEach((player) => {
                    postData.participants.push(player);
                    postData.classes = [...postData.classes, ...team[player]];
                  });
                });
                const endpoint = authMethod
                  ? `/matches/update`
                  : `/modtasks/create`;
                postData = authMethod
                  ? postData
                  : {
                      creatorUid: (user || {}).uid,
                      GameId: game.id,
                      GameSlug: match.GameSlug,
                      type: `match_update`,
                      referenceId: match.id,
                      dataAfter: postData,
                    };
                const res = await postToApi(endpoint, postData, "POST", {
                  authorization: authToken || token,
                  authMethod,
                });
                if (res.errors) {
                  analytics.save(`edit_match_data_error`, {
                    postData,
                    error: res.errors,
                  });
                  return setError(res.errors[0].message);
                }
                // give user feedback they have pending edits? cache their edits?
                // what if task gets resolved? would have to refetch
                if (!authMethod) setTimeout(() => alert(EDIT_THANK_MSG), 500);
                analytics.save(`edit_match_data`, { postData, res });
                closeModal();
              };
              // originally postMatchUpdate was turned into a func for onlogin callback
              // arg is an object bc AuthModal onLogin gives a user obj with a token
              // leaving here in case we want to force login for edits
              postMatchUpdate({ authToken }).then(() => {
                authMethod && window.open(`/video/${match.id}`, "_self");
              });
            }}
          >
            SUBMIT
          </button>
          <button className="danger ml-5 w-1-2" onClick={closeModal}>
            CANCEL
          </button>
        </div>
        {error && <div className="danger">{error}</div>}
      </div>
    </Modal>
  );
});

export default EditMatchModal;

// the state management / update calls here are a little nasty,
// but w/ the benefit of supporting an infinite/arbitrary
// # of team sides (for battle royales, 3+ team games, etc.)
const PlayerClassTagger = memo(({ match = {}, onChange }) => {
  let [gameClasses] = useApi(`/games/classes/${match.GameSlug}`, null, [
    "classes",
  ]);
  gameClasses = gameClasses || {};
  const { classes } = gameClasses;
  const classDisplayName = gameClasses.classDisplayName || "Class";
  const pluralDispName = strings.pluralize(classDisplayName);
  const [playerInput, setPlayerInput] = useState(["", ""]);
  const updatePlayerInput = (i, playerName) => {
    const pI = [...playerInput];
    pI[i] = playerName;
    setPlayerInput(pI);
  };

  const [classSelection, setClassSelection] = useState([[], []]);
  // ^ could be any number of sides
  const [savedTeammates, setSavedTeammates] = useState([], []);

  useEffect(() => {
    if (!match) return;
    else if (match.participants && !match.teams)
      return setPlayerInput(match.participants);
    else if (!match.teams) return;

    const playerInput = [];
    const classSelection = [];
    // clone the objects to not fuck up cache
    const teams = (typeof match.teams === "string"
      ? JSON.parse(match.teams)
      : match.teams
    ).map((t) => ({ ...t }));
    teams.forEach((teamObj = {}) => {
      const teamKeys = Object.keys(teamObj);
      const currentPlayer = teamKeys[teamKeys.length - 1];
      playerInput.push(currentPlayer);
      classSelection.push(teamObj[currentPlayer] || []);
      delete teamObj[currentPlayer];
    });
    setPlayerInput(playerInput);
    setClassSelection(classSelection);
    setSavedTeammates(teams);
  }, [match]);

  useEffect(() => {
    const submitData = [...savedTeammates];
    playerInput.forEach((playerName, side) => {
      if (playerName) {
        submitData[side] = {
          ...savedTeammates[side],
          [playerName]: classSelection[side],
        };
      }
    });
    onChange(submitData);
    // eslint-disable-next-line
  }, [playerInput, classSelection, savedTeammates]);

  return (
    <>
      <h3 className="flex-center">Identify Players & {pluralDispName}</h3>
      <br />
      <div className="flex-around">
        {/* ARR can be of ANY size you want, any # of teams */}
        {[0, 1].map((side, i) => {
          const isSubmittable =
            (classSelection[side] || []).length && playerInput[side];
          return (
            <div key={`side-${side}`}>
              <span className="flex-column">
                <h3>Side {i + 1}</h3>
                {savedTeammates[side] &&
                  Object.keys(savedTeammates[side]).map((player) => (
                    <div key={player} className="flex-middle">
                      <div className="mr-2">{player}:</div>
                      {classes &&
                        savedTeammates[side][player].map((c) => (
                          <img
                            className="h-5"
                            key={c}
                            title={c}
                            src={classes[c].iconURL}
                            alt=""
                          />
                        ))}
                    </div>
                  ))}
                <Input
                  label="Player:"
                  onChange={(p) => updatePlayerInput(side, p)}
                  value={playerInput[side]}
                />
                <ClassSearchInput
                  gameClasses={gameClasses}
                  label={pluralDispName}
                  // resetData={savedTeammates[side]}
                  onSelect={(c) =>
                    setClassSelection({
                      ...classSelection,
                      [side]: c,
                    })
                  }
                  showSpinner={false}
                  initialCharacters={classSelection[side]}
                />
                <h4
                  className={`m-2 link ${isSubmittable ? "" : `hidden`}`}
                  onClick={() => {
                    if (!isSubmittable) return;
                    savedTeammates[side] = {
                      ...savedTeammates[side],
                      [playerInput[side]]: classSelection[side],
                    };
                    setSavedTeammates(savedTeammates);
                    updatePlayerInput(side, "");
                    setClassSelection({ ...classSelection, [side]: [] });
                  }}
                >
                  + Add Teammate
                </h4>
              </span>
              {/* {i < 1 && <div className="Divider vertical mr-8" />} */}
            </div>
          );
        })}
      </div>
    </>
  );
});

const EDIT_THANK_MSG = `Thank you! Your change will be reviewed by our moderators.`;
