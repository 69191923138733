import React, { useContext, useState } from "react";

import { UserContext } from "../../../context/UserContext";
import { analytics } from "../../../helpers";
import { useModal } from "../../../hooks";
import { postToApi } from "../../../hooks/useApi";
import { Form, Modal } from "../../reusable";
import { FormDivider } from "../../reusable/Form/Form";
import SocialLoginBtn from "../LoginModal/SocialLoginBtn";
import LoginModal from "../LoginModal/LoginModal";
import FollowGameModal from "../FollowGameModal/FollowGameModal";

const RegisterModal = ({ animation, onLogin: onLoginHandler }) => {
  const { createUser, onLogin } = useContext(UserContext);
  const { setModal } = useModal();

  const [isRegistering, setIsRegistering] = useState(false);
  const [err, setErr] = useState(null);

  return (
    <div className="LoginModal ">
      <Modal animation={animation}>
        <div className="flex-column flex-center flex-middle">
          <Form
            err
            isLoading={isRegistering}
            title="+ New Account"
            fields={{ email: "string", password: "string" }}
            onSubmit={({ email, password }) => {
              onLoginHandler && onLogin(onLoginHandler);
              setIsRegistering(true);
              createUser(email, password)
                .then((userAuth) => {
                  analytics.save("create_account", { provider: "email" });
                  setModal(<FollowGameModal />);
                  postToApi(`/users/create`, { email, uid: userAuth.id });
                  alert(`Awesome! Let's set you up with some games!`);
                })
                .catch((createUserErr) => {
                  console.log("err creating user:", createUserErr);
                  analytics.save("create_account_err", {
                    provider: "email",
                    error: createUserErr,
                  });
                  setIsRegistering(false);
                  setErr(createUserErr.message);
                });
            }}
            submitText="CREATE ACCOUNT"
          >
            {err && <div className="danger mt-5 text-center">{err}</div>}
          </Form>
          <FormDivider>OR</FormDivider>
          <SocialLoginBtn
            isNew
            type="Google"
            icon={assetMap.Google.icon}
            backgroundColor={assetMap.Google.color}
            onLogin={(res) => {
              onLoginHandler && onLoginHandler(res);
              analytics.save("create_account", { provider: "google" });
              alert(`Awesome! Let's set you up with some games!`);
              setModal(<FollowGameModal />);
            }}
          />
          <p
            className="link mt-8"
            onClick={() =>
              setModal(<LoginModal animation={null} onLogin={onLoginHandler} />)
            }
          >
            I already have an account
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default RegisterModal;

const assetMap = {
  Google: {
    color: "#DB4437",
    icon: "logo-google",
  },
};
