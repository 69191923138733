import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import Youtube from "react-youtube";

import { useApi, useModal, useReadDb } from "../../hooks";
import { analytics, cache, dates, strings } from "../../helpers";
import { postToApi } from "../../hooks/useApi";
import {
  usePlaylistWatchHistory,
  getHistoryFromCache,
} from "../../hooks/useWatchHistory";
import { EditMatchModal, ShareModal } from "../../components/modals";
import {
  FollowButton,
  Icon,
  Playlist,
  Screen,
} from "../../components/reusable";
import "./VideoScreen.scss";
import { getCache, setCache } from "../../helpers/cacheHelper";

const VideoScreen = () => {
  const { matchId } = useParams();
  const [match] = useApi(`/matches/${matchId}`, null, ["teams", "videos"]);
  const videos = match && match.videos;
  const providerData = videos && strings.parseIfJSON(videos[0].providerData);
  const [game] = useApi(
    match && match.GameSlug ? `/games/${match.GameSlug}` : null
  );
  game && cache.cacheGameIcons([game]);
  const [event] = useApi(
    match && match.EventId ? `/events/${match.EventId}` : null
  );
  const [isHidingSpoilers, setIsHiding] = useState(true);

  // FOLLOWING LOGIC USES WATCH HISTORY TO HANDLE START TIME OF VIDEO,
  // WORKS FOR BOTH INITIAL LOAD AS WELL AS SWAPPING VIDS VIA PLAYLIST
  const [videoStartTime, setVideoStartTime] = useState(null);
  const [watchHistory, updateHistory] = usePlaylistWatchHistory(
    match ? match.EventId : undefined
  );
  // handle initial history load
  useEffect(() => {
    if (!match || !match.id || watchHistory) return;
    const eventHist = getHistoryFromCache(match.EventId) || {};
    const matchHist = eventHist[match.id] || {};
    const t = matchHist.progressPct === 1 ? 0 : matchHist.progressTime || 0;
    setVideoStartTime(t);
  }, [match]); // eslint-disable-line
  // handle video changed
  useEffect(() => {
    if (!match || !watchHistory) return;
    const hist = watchHistory[match.id] || {};
    const t = hist.progressPct === 1 ? 0 : hist.progressTime || 0;
    setVideoStartTime(t);
  }, [match]); // eslint-disable-line

  return (
    <Screen showSideMenu={false} className="VideoScreen">
      {videos && videoStartTime !== null ? (
        <>
          <div className="video-container">
            <VideoPlayer
              videoId={videos[0].providerId}
              onCompletionChange={(per, time) => {
                time > 20 && updateHistory(event.id, matchId, per, time);
              }}
              match={match}
              hasMore={match?.playlistPosition < event?.numVideos - 1}
              startAt={videoStartTime}
            />
          </div>
          <VideoInfo
            event={event}
            match={match}
            game={game}
            description={providerData.snippet.description}
            publishedAt={providerData.snippet.publishedAt}
          />
          <div className="playlist-container flex-column">
            {event && (
              <Playlist
                event={event}
                playlistPosition={match.playlistPosition}
                isHidingSpoilers={isHidingSpoilers}
                setIsHiding={setIsHiding}
                watchHistory={watchHistory}
              />
            )}
          </div>
        </>
      ) : (
        <div className="flex-center mt-8">
          <Icon name="spinner" size="xl" />
        </div>
      )}
    </Screen>
  );
};

export default VideoScreen;

const VideoPlayer = ({
  videoId,
  onCompletionChange,
  match,
  hasMore,
  startAt = 0,
}) => {
  const [isShowingControls, setIsShowingControls] = useState(
    getCache("show-video-controls")
  );
  const [pauseClicked, setPauseClicked] = useState(false);
  const [videoTime, setVideoTime] = useState(startAt);
  const [startTime, setStartTime] = useState(startAt);
  const [durationEstablished, setDurationEstablished] = useState(
    match.duration
  );
  useEffect(() => {
    setDurationEstablished(match.duration);
  }, [match]);
  useEffect(() => {
    setVideoTime(startAt);
    setStartTime(startAt);
  }, [startAt]);

  const history = useHistory();

  return (
    <div className="VideoPlayer">
      {pauseClicked && (
        <button
          className={`youtube-controls icon-left ${
            isShowingControls ? "showing info" : "hiding youtube"
          }`}
          onClick={() => {
            setStartTime(videoTime);
            setIsShowingControls(!isShowingControls);
            setCache("show-video-controls", !isShowingControls);
          }}
        >
          <Icon
            name={`${isShowingControls ? "eye-off-outline" : "logo-youtube"}`}
          />
          {isShowingControls ? "HIDE" : "SHOW"} CONTROLS
        </button>
      )}
      <Youtube
        videoId={videoId}
        containerClassName={"yt-container"}
        opts={{
          playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            controls: isShowingControls ? 1 : 0,
            modestbranding: 1,
            rel: 0,
            start: parseInt(startTime),
          },
        }}
        onReady={(v) => console.log("YT vid rdy:", v)}
        onEnd={({ target }) => {
          onCompletionChange(1, target.playerInfo.duration);
          // this will need to be updated at some point
          // currently all the videos are incremented by 1, but that may change
          hasMore && history.push(`/video/${parseInt(match.id) + 1}`);
        }}
        onStateChange={({ target, data: statusCode }) => {
          // https://developers.google.com/youtube/iframe_api_reference#Events
          const { currentTime, duration } = target.playerInfo;
          currentTime > videoTime && setVideoTime(currentTime);
          const completionRatio = currentTime / duration;
          // prevent 0/0 (weird youtube thing when loading)
          !isNaN(completionRatio) &&
            onCompletionChange(completionRatio, currentTime);
          if (duration && !durationEstablished) {
            setDurationEstablished(duration);
            postToApi(`/matches/setDuration`, { id: match.id, duration });
          }
        }}
        onPause={() => setPauseClicked(true)}
        // onPlay={func}
        // onError={func}
        // onPlaybackRateChange={func}
        // onPlaybackQualityChange={func}
      />
    </div>
  );
};

const VideoInfo = ({ event, game, match, description, publishedAt }) => {
  const { setModal } = useModal();
  const admins = event && JSON.parse(event.admins);
  const adminIds = admins && event && Object.keys(admins);
  const uploaderId =
    admins &&
    adminIds &&
    adminIds.find((uid) => admins[uid].rank === "creator");
  const uploaderAccount = useReadDb(
    uploaderId ? `/users/${uploaderId}/publicInfo/promotedAccount` : ""
  );
  const [gameClasses] = useApi(`/games/classes/${match.GameSlug}`, null, [
    "classes",
  ]);
  const { participants } = match;
  const history = useHistory();

  return (
    <div className="VideoInfo mt-2 p-2">
      <div className="VideoInfo-header flex-between flex-top">
        {/* title / share btn row */}
        <span>
          <h3 className="text-bold">{match && match.name}</h3>
        </span>
        <div className="flex-column">
          {/* <button
          title="Like"
          className="danger icon-left"
          onClick={() => alert("Like btn: COMING SOON!")}
        >
          <Icon name="heart"></Icon>
          523
        </button> */}
          <button
            title="Share"
            className="share-btn success icon-left"
            onClick={() => {
              analytics.save("share_button_click", { match });
              setModal(<ShareModal matchId={match.id} />);
            }}
          >
            <Icon name="share-social"></Icon>
            SHARE
          </button>
        </div>
      </div>
      <span className="flex-between flex-wrap flex-middle">
        {/* Date published, classes, tag button */}
        <div className="flex-wrap flex-middle ">
          <h3 className="info">{`${strings.capitalize(
            dates.formatDate(publishedAt)
          )}`}</h3>
          <div
            className="flex ml-5"
            onClick={() => setModal(<EditMatchModal match={match} />)}
          >
            {gameClasses && match.teams ? (
              match.teams.map((team, i) => (
                <span key={i} className="flex">
                  {i > 0 && <b className="mx-2">VS</b>}
                  {Object.keys(team).map((playerName, pI) => (
                    <div className="flex-middle" key={pI}>
                      {pI > 0 && <span>&nbsp;|&nbsp;</span>}
                      {playerName && playerName !== "undefined" && (
                        <>
                          <div
                            className="link"
                            onClick={(e) => {
                              e.stopPropagation();
                              history.push(
                                `/player/${game.slug}/${strings.slugify(
                                  playerName
                                )}`
                              );
                            }}
                          >
                            {playerName}
                          </div>
                          :&nbsp;
                        </>
                      )}
                      {team[playerName].length ? (
                        team[playerName].map((gameClass, i) =>
                          gameClasses && gameClasses.classes[gameClass] ? (
                            <img
                              className="h-5"
                              src={gameClasses.classes[gameClass].iconURL}
                              alt=""
                              key={i}
                              title={gameClass}
                            />
                          ) : gameClass ? (
                            <i key={i}>
                              {gameClass}
                              {i < team[playerName].length - 1 && (
                                <span>,&nbsp;</span>
                              )}
                            </i>
                          ) : (
                            <></>
                          )
                        )
                      ) : (
                        <NoClassLink match={match} />
                      )}
                    </div>
                  ))}
                </span>
              ))
            ) : participants ? (
              participants.map((p, i) => (
                <span key={i}>
                  {p}:&nbsp;
                  <NoClassLink match={match} />{" "}
                  {i < participants.length - 1 && "|"}&nbsp;
                </span>
              ))
            ) : (
              <h4
                className="link"
                onClick={() => setModal(<EditMatchModal match={match} />)}
              >
                + Tag Players & Characters
              </h4>
            )}
          </div>
        </div>
        {/* {gameClasses && match.teams && (
          <h4
            // className="link pt-2"
            className="link"
            onClick={() => setModal(<EditMatchModal match={match} />)}
          >
            + Tag Players & Characters
          </h4>
        )} */}
      </span>
      <div className="VideoInfo-icon-row flex-middle mt-2">
        <div className="VideoInfo-icon-body flex-between flex-middle w-1-1">
          {game && (
            <span className="game-and-follow flex-middle flex-wrap flex-1">
              <Link
                to={`/game/${game.slug}`}
                onClick={() =>
                  analytics.save("game_label_click", { screen: "VideoScreen" })
                }
              >
                <div className="flex-middle">
                  <img src={game.iconURL} alt="" />
                  <div className="dark m-8">{game.name}</div>
                </div>
              </Link>
              <FollowButton game={game} />
            </span>
          )}
          <SocialAccount
            uploaderAccount={uploaderAccount}
            createdAt={match.createdAt}
          />
        </div>
      </div>
      <div className="Divider mt-8  mb-8"></div>
      <div className="VideoInfo-summary flex-col mt-5 mb-5">
        <TextWithBreaks text={description} />
      </div>
    </div>
  );
};

const SocialAccount = ({ uploaderAccount, createdAt }) => {
  if (!uploaderAccount)
    return (
      <span
        className="VideoInfo-uploader flex-middle"
        onClick={() => window.open(`https://instagram.com/ggvods`, "_blank")}
      >
        <Icon name={`logo-instagram`} className={`instagram text-4xl mr-2`} />
        <span className="mb-2">
          GGvods AI
          <div className="info">Added {dates.formatDate(createdAt)}</div>
        </span>
      </span>
    );

  const platform = Object.keys(uploaderAccount)[0];
  const username = uploaderAccount[platform];

  return (
    <span className="VideoInfo-uploader flex-middle">
      <Icon name={`logo-${platform}`} className={`${platform} text-4xl mr-2`} />
      <div
        className="flex-column mb-2"
        onClick={() => window.open(getSocialUrl[platform](username), "_blank")}
      >
        {username}
        <div className="info">Added {dates.formatDate(createdAt)}</div>
      </div>
    </span>
  );
};

const TextWithBreaks = ({ text }) =>
  text.split("\n").map((line, i) => (
    <span key={i}>
      {renderTextWithLinks(line)}
      <br />
    </span>
  ));

const URL_WITH_HTTP =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

const renderTextWithLinks = (txt) =>
  txt.split(" ").map((part, i) =>
    URL_WITH_HTTP.test(part) ? (
      <a className="link" key={i} href={part}>
        {part}{" "}
      </a>
    ) : (
      <span key={i}>{part} </span>
    )
  );

const getSocialUrl = {
  twitch: (username) => `https://twitch.tv/${username}`,
  twitter: (username) => `https://twitter.com/${username}`,
  instagram: (username) => `https://instagram.com/${username}`,
  youtube: (username) => `https://www.youtube.com/user/${username}`,
};

const NoClassLink = ({ match }) => {
  const { setModal } = useModal();
  return (
    <span
      className="link"
      onClick={() => setModal(<EditMatchModal match={match} />)}
    >
      ???
    </span>
  );
};
