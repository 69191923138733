import React from "react";

import {
  FollowedGamesList,
  Screen,
  RecommendedGamesList,
} from "../../components/reusable";

const MyGamesScreen = () => {
  return (
    <Screen className="MyGames mt-5 pl-5" showSideMenu={true}>
      <FollowedGamesList />
      <div className="m-8" />
      <RecommendedGamesList />
    </Screen>
  );
};

export default MyGamesScreen;
