import React, { useContext } from "react";

import { useModal } from "../../hooks";
import { UserContext } from "../../context/UserContext";
import { CreateGameModal } from "../../components/modals";
import { Screen } from "../../components/reusable";

const SuperAdminScreen = () => {
  const { setModal } = useModal();
  const { authToken, role } = useContext(UserContext);

  if (role !== "superadmin") return null;
  return (
    <Screen className="p-12">
      <button
        className=""
        onClick={() => {
          setModal(
            <CreateGameModal
              game={null}
              authMethod={"superadmin"}
              authToken={authToken}
            />
          );
        }}
      >
        CREATE GAME
      </button>
    </Screen>
  );
};

export default SuperAdminScreen;
