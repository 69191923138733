import React from "react";

import { analytics } from "../../../helpers";
import { Icon, Modal } from "../../reusable";

const ShareModal = ({ matchId }) => {
  return (
    <Modal>
      <h3>Share this match</h3>
      <br />
      <div className="flex">
        <button
          className="icon-left bg-facebook light mr-8"
          onClick={() => {
            analytics.save("share_match", { matchId, platform: "facebook" });
            window.open(linkMakers.facebook(matchId), "_blank");
          }}
        >
          <Icon name="logo-facebook" />
          Share on Facebook
        </button>

        <br />
        <button
          className="icon-left bg-twitter light"
          onClick={() => {
            analytics.save("share_match", { matchId, platform: "twitter" });
            window.open(linkMakers.twitter(matchId), "_blank");
          }}
        >
          <Icon name="logo-twitter" />
          Share on Twitter
        </button>
      </div>
    </Modal>
  );
};

export default ShareModal;

const linkMakers = {
  facebook: (matchId) =>
    `https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwww.ggvods.com%2Fvideo%2F${matchId}`,
  twitter: (matchId) =>
    `https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.ggvods.com%2Fvideo%2F${matchId}&amp;text=E-sports%20On%20Demand%3A%20Watch%20any%20tournament%2C%20spoiler%20free`,
};

// const getFacebookLink = matchId => `https://www.facebook.com/sharer/sharer.php?u=h

// const getTwitterLink = matchId => `https://twitter.com/intent/tweet?url=https%3A%2F%2Fwww.ggvods.com%2Fvideo%2FmatchId&amp;text=E-sports%20On%20Demand%3A%20Watch%20any%20tournament%2C%20spoiler%20free`
