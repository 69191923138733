import React, { useContext, useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import { UserContext } from "../../context/UserContext";
import { cache } from "../../helpers";
import { analytics } from "../../helpers";
import { postToApi } from "../../hooks/useApi";
import { useApi, useModal } from "../../hooks";
import { EventUploadModal } from "../../components/modals";
import {
  ActivatedInput,
  BannerNavScreen,
  Icon,
  PaginatedScroll,
  Toggle,
  VideoSection,
} from "../../components/reusable";
import "./EventScreen.scss";

const EventScreen = () => {
  const { gameSlug, eventId } = useParams();
  const { setModal } = useModal();
  const history = useHistory();
  const { authToken, determineAuthMethod } = useContext(UserContext);
  let [event] = useApi(`/events/${eventId}`);
  let [game] = useApi(`/games/${gameSlug}`);
  game && cache.cacheGameIcons([game]);

  event = event || {};
  game = game || {};

  const authMethod = determineAuthMethod(game, event);
  const [matchQuery, setMatchQuery] = useState(null);

  return (
    <BannerNavScreen
      className="EventScreen content"
      bannerImage={event.bannerURL || game.bannerURL}
      navOptions={{
        Vods: ({ scrollRef }) => (
          <VodsScroller
            eventId={eventId}
            game={game}
            scrollRef={scrollRef}
            iconURL={game.iconURL}
            query={matchQuery}
          />
        ),
        Highlights: () => <Highlights />,
        Analysis: () => <Analysis />,
        About: () => <About />,
      }}
      hideOptions
      name={event.name}
      icon={game.iconURL}
      iconLink={`/game/${gameSlug}`}
      iconTitle="Go to game"
      renderNavRight={() => (
        <>
          {matchQuery && (
            <button
              className="danger icon-left mr-5"
              onClick={() => {
                analytics.save("clear_search_res_game_screen", matchQuery);
                setMatchQuery();
              }}
            >
              <Icon name="close" />
              CLEAR SEARCH
            </button>
          )}
          <ActivatedInput
            onSubmit={(query) => setMatchQuery(query)}
            placeholder="Search for a match.."
          />
          {authMethod && (
            <>
              <button
                className="info icon-left ml-5"
                onClick={() =>
                  setModal(<EventUploadModal game={game} event={event} />)
                }
              >
                <Icon name="pencil"></Icon>
                EDIT EVENT
              </button>
              <button
                className="danger icon-left ml-5"
                onClick={async () => {
                  if (
                    window.confirm("This will permanently delete this event.")
                  ) {
                    await postToApi(`/events/${event.id}`, null, "DELETE", {
                      authorization: authToken,
                      authMethod,
                    });
                    history.push("/");
                  }
                }}
              >
                <Icon name="close"></Icon>
                DELETE EVENT
              </button>
            </>
          )}
          {/* <button
            onClick={() => setModal(<EventUploadModal game={game} />)}
            className="success ml-5 icon-left"
          >
            <Icon name="trophy" />
            UPLOAD
          </button> */}
        </>
      )}
    />
  );
};

export default EventScreen;

const VodsScroller = ({ iconURL, eventId, scrollRef, query }) => {
  const { gameSlug } = useParams();
  return (
    <div className="Event-vods p-5 pr-8">
      <PaginatedScroll
        threshold={0}
        getScrollParent={() => scrollRef.current}
        endpoint={
          query
            ? `/matches/${gameSlug}/search?titleQuery=${query}&EventId=${eventId}&order=playlistPosition`
            : `/matches/event/${eventId}`
        }
        renderChildren={({ data, total, isLoading }) =>
          data ? (
            <VodsList
              data={data}
              total={total}
              eventId={eventId}
              iconURL={iconURL}
              query={query}
            />
          ) : isLoading ? (
            <div className="flex-center success">
              <Icon name="spinner" size="xl" />
            </div>
          ) : (
            <h2 className="mobile-p-5">No Matches Uploaded</h2>
          )
        }
      />
    </div>
  );
};

const VodsList = ({ data, total, iconURL, query }) => {
  const [matches, setMatches] = useState([]);
  const [isHidingSpoilers, setIsHidingSpoilers] = useState(true);
  useEffect(() => setMatches(formatVideos(data)), [data]);
  return matches.length ? (
    <VideoSection
      cardIcon={iconURL}
      isHidingSpoilers={!query && isHidingSpoilers}
      header={
        <div className="flex-between flex-wrap">
          {query ? (
            <div>
              Search Results for "{query}" ({total}):
            </div>
          ) : (
            <>
              <span className="flex">
                {/* Top 8 */}
                {total} Videos
                <WatchBtn matchId={matches[0].id} />
              </span>
              <div
                className="pr-8 mr-5 spoiler-toggle flex-middle cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  analytics.save("toggle_spoilers", {
                    screen: "EventScreen",
                    hideSpoilers: !isHidingSpoilers,
                  });
                  setIsHidingSpoilers(!isHidingSpoilers);
                }}
              >
                <span className="text-2xl mr-5">Hide Spoilers</span>
                <Toggle value={isHidingSpoilers} />
              </div>
            </>
          )}
        </div>
      }
      events={matches || []}
    />
  ) : (
    <span></span>
  );
};

const Highlights = () => (
  <div className="Event-highlights p-5">Event Highlights: COMING SOON!</div>
);
const Analysis = () => (
  <div className="Event-analysis p-5">Event Analysis: COMING SOON!</div>
);

const WatchBtn = ({ matchId }) => (
  <Link to={`/video/${matchId}`}>
    <button
      className="ml-5 icon-left"
      onClick={() =>
        analytics.save("watch_button_click", { screen: "EventScreen", matchId })
      }
    >
      <Icon name="play"></Icon>
      WATCH
    </button>
  </Link>
);

const About = () => (
  <div className="Event-about p-5">Event Info: COMING SOON!</div>
);

export const formatVideos = (matches = [], iconURL) => {
  if (!matches || !matches.length) return matches;
  const formattedEvents = matches.map((ev) => ({
    containsSpoilers: ev.playlistPosition > 0, // && !(firstRound && ev.round === firstRound),
    ...ev,
    iconURL,
  }));
  return formattedEvents;
};
