import React, { useState, useMemo, useContext } from "react";
import { useParams } from "react-router-dom";

import { cache, strings } from "../../helpers";
import { useApi, useModal } from "../../hooks";
import { postToApi } from "../../hooks/useApi";
import { uploadDocument } from "../../components/reusable/Form/Form";
import {
  ImageInput,
  PaginatedScroll,
  Screen,
  VerticalMatchList,
} from "../../components/reusable";
import { UserContext } from "../../context/UserContext";
import "./PlayerProfileScreen.scss";
import { LoginModal, RegisterModal } from "../../components/modals";

const PlayerProfileScreen = () => {
  const [filteredClasses, setFilteredClasses] = useState(null);
  const { playerSlug, gameSlug } = useParams();
  const [playerData] = useApi(`/players/${gameSlug}/${playerSlug}`);
  const [gameData] = useApi(`/games/${gameSlug}`);
  const [gameClasses] = useApi(`/games/classes/${gameSlug}`, null, ["classes"]);
  const recentMatchesEndpoint = `/matches/${gameSlug}/search?participants=${playerSlug}${
    filteredClasses ? `&classes=${filteredClasses.join()}` : ``
  }`;

  const { authToken, determineAuthMethod, user } = useContext(UserContext);
  const { setModal } = useModal();

  let authMethod = useMemo(
    () => determineAuthMethod(gameData),
    // eslint-disable-next-line
    [gameData, authToken, determineAuthMethod]
  );
  const isAdmin = ["superadmin", "gameadmin"].includes(authMethod);

  const [uploadedPhoto, setUploadedPhoto] = useState(null);
  return (
    <Screen
      className="PlayerProfileScreen content p-5"
      renderChildren={({ scrollRef }) => {
        return (
          <>
            {playerData && (
              <>
                <div className="Profile-header flex">
                  <span
                    onClick={() => {
                      if (authToken) return;
                      const hasAccount = cache.getCache("HAS_CREATED_ACCOUNT");
                      setModal(hasAccount ? <LoginModal /> : <RegisterModal />);
                    }}
                  >
                    <ImageInput
                      disabled={!authToken}
                      value={
                        playerData.imageURL
                          ? { imgSrc: uploadedPhoto || playerData.imageURL }
                          : null
                      }
                      noImageMessage={<i className="mt-2">No image yet</i>}
                      onChange={({ file }) =>
                        uploadDocument(
                          file,
                          `/games/${gameSlug}/players/${playerSlug}`,
                          (err, imageURL) => {
                            console.log("upload success:", imageURL);
                            if (err) return console.log(err);
                            setUploadedPhoto(imageURL);
                            const postData = {
                              imageURL,
                              id: playerData.id,
                              GameSlug: gameData.slug,
                            };
                            postToApi(
                              isAdmin
                                ? `/players/updatePhoto`
                                : `/modtasks/create`,
                              isAdmin
                                ? postData
                                : {
                                    creatorUid: (user || {}).uid,
                                    GameId: gameData.id,
                                    GameSlug: gameData.slug,
                                    type: `player_update`,
                                    referenceId: playerData.id,
                                    referenceSlug: playerSlug,
                                    dataAfter: { imageURL },
                                  },
                              "POST",
                              {
                                authorization: authToken,
                                authMethod,
                              }
                            );
                            !isAdmin &&
                              alert("Your image is pending admin approval");
                          },
                          true
                        )
                      }
                    />
                  </span>
                  <div className="Profile-info flex-column ml-5">
                    <div className="flex flex-middle flex-wrap">
                      {gameData && (
                        <img
                          className="h-8 mr-2"
                          src={gameData.iconURL}
                          alt=""
                        />
                      )}
                      <h1>{playerData.gamertag}&nbsp;</h1>
                    </div>
                    <h2>
                      {playerData.TeamSlug &&
                        `Team: ${strings.slugToTitle(playerData.TeamSlug)}`}
                    </h2>
                    {gameClasses && playerData.classes && (
                      <div className="flex">
                        {playerData.classes.map((gameClass) =>
                          gameClasses && gameClasses.classes[gameClass] ? (
                            <img
                              className="h-10"
                              key={gameClass}
                              src={gameClasses.classes[gameClass].iconURL}
                              alt=""
                              title={gameClass}
                            />
                          ) : (
                            <span />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            <div className="Divider my-8" />
            <div className="mt-8 pt-8 pl-2 mb-5">
              <h1 className="">Recent Matches </h1>
              <h2>
                {/* {gameClasses && `Sort by ${gameClasses.classDisplayName || `class`}:`} */}
                {gameClasses && playerData && playerData.classes && (
                  <ClassSelector
                    options={playerData.classes.map((c) => ({
                      ...gameClasses.classes[c],
                      name: c,
                    }))}
                    onSelect={(selectedClasses) => {
                      setFilteredClasses(selectedClasses);
                      console.log("sel classes:", selectedClasses);
                    }}
                  />
                )}
              </h2>
            </div>
            <PaginatedScroll
              getScrollParent={() => scrollRef.current}
              endpoint={recentMatchesEndpoint}
              renderChildren={({ data }) => (
                <VerticalMatchList
                  matches={data}
                  gameClasses={gameClasses || {}}
                  playersToHighlight={[playerSlug]}
                  threshold={500}
                />
              )}
            />
          </>
        );
      }}
    />
  );
};

const ClassSelector = ({ options, onSelect }) => {
  const [classes, selectClasses] = useState([]);
  return (
    <div className="flex-middle mt-2">
      <div className="mr-2">Filter:</div>
      {options.map((gameClass) => {
        const selectedIndex = classes.findIndex(
          (c) => gameClass.name === c.name
        );
        const isSelected = selectedIndex > -1;
        return gameClass ? (
          <img
            className={`mr-2 h-10 class-icon cursor-pointer ${
              isSelected ? ` highlighted` : ``
            }`}
            key={gameClass.name}
            src={gameClass.iconURL}
            alt=""
            title={gameClass.name}
            onClick={() => {
              let newArr = [...classes];
              if (isSelected) newArr.splice(selectedIndex, 1);
              else newArr = [...newArr, gameClass];
              selectClasses(newArr);
              onSelect(newArr.map((c) => c.name));
            }}
          />
        ) : (
          <span />
        );
      })}
    </div>
  );
};

export default PlayerProfileScreen;
