import React from "react";
import ReactDOM from "react-dom";
import * as firebase from "firebase/app";
import "./assets/styles/AppStyles.scss";
import "./assets/styles/design_system/GlobalStyles.scss";
import "@firebase/analytics";

import App from "./App";
import { FIREBASE_CONFIG } from "./config/config";
import * as serviceWorker from "./serviceWorker";

// Initialize Firebase
firebase.initializeApp(FIREBASE_CONFIG);
firebase.analytics().setAnalyticsCollectionEnabled(true);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
