import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";
import { analytics, strings } from "../../../helpers";
import { useModal } from "../../../hooks";
import { postToApi, fetchFromApi } from "../../../hooks/useApi";
import { uploadDocumentSync } from "../../reusable/Form/Form";
import { Form, Input, Modal, Select } from "../../reusable";
import AuthModal from "../AuthModal/AuthModal";

const EventUploadModal = ({ game, event }) => {
  const { closeModal } = useModal();
  const { user, authToken, determineAuthMethod } = useContext(UserContext);
  const history = useHistory();
  const [playlistData, setPlaylistData] = useState(null);
  const [playlistErr, setPlaylistErr] = useState(null);
  const [tier, setTier] = useState((event && event.tier) || "local");
  const thumbnail =
    playlistData && getBestThumb(playlistData.playlistInfo.snippet.thumbnails);
  const { setModal } = useModal();

  return (
    <Modal showCloseBtn>
      <div className="ml-5">
        <div className="flex-middle">
          <img className="h-8" src={game.iconURL} alt="" />
          <p className="text-3xl ml-2">Upload Event</p>
        </div>
        <div className="w-1-1">
          {!event && (
            <>
              <label htmlFor="form-stacked-text">Youtube Playlist URL</label>
              <Input
                onChange={async (url) => {
                  if (isValidURL(url)) {
                    const res = await fetchPlaylistData(url, game.slug);
                    res.error
                      ? setPlaylistErr(res.error.message)
                      : setPlaylistData(res);
                  } else {
                    setPlaylistErr(url === "" ? null : "Invalid playlist url");
                  }
                }}
                containerClass="mt-2 w-4-5"
                placeholder={
                  event
                    ? `https://www.youtube.com/playlist?list=${event.playlistId}`
                    : ""
                }
              />
            </>
          )}
        </div>
        <br />
        <label className="mr-2">Event Tier:</label>

        <Select
          value={tier}
          onChange={setTier}
          options={["local", "major", "supermajor"]}
        />
        {playlistErr && <div className="danger">{playlistErr}</div>}
        {playlistData && (
          <div className="flex mt-5">
            <img className="border-radius w-1-4" src={thumbnail} alt="" />
            <div className="ml-5">
              <b>{playlistData.playlistInfo.snippet.title}</b>
              <br />
              <i>{playlistData.playlistInfo.snippet.channelTitle}</i>
              <br />
              {playlistData.formattedMatches.length} Videos
            </div>
          </div>
        )}
      </div>
      <Form
        defaultValues={
          playlistData || event
            ? {
                tournamentName: event
                  ? event.name
                  : playlistData.playlistInfo.snippet.title,
                eventImage: event ? event.imageURL : thumbnail,
                bannerImage: event ? event.bannerURL : null,
              }
            : {}
        }
        fields={{
          tournamentName: "string",
          eventImage: "image",
          bannerImage: "image",
        }}
        shouldUploadFiles={false}
        onSubmit={async (formData) => {
          const postEventToApi = async (token, uid) => {
            const { formattedMatches, playlistInfo } = playlistData || {};
            const matchData =
              playlistData && formattedMatches
                ? {
                    numVideos: formattedMatches.length,
                    formattedMatches,
                    playlistId: playlistInfo.id,
                  }
                : {};
            const sluggedName = strings.slugify(formData.tournamentName);
            // wont upload if already a url
            const imageURL = await uploadDocumentSync(
              formData.eventImage,
              `/games/${game.slug}/events/${sluggedName}/icon`
            );
            const bannerURL = await uploadDocumentSync(
              formData.bannerImage,
              `/games/${game.slug}/events/${sluggedName}/banner`
            );
            const eventData = {
              ...event,
              name: formData.tournamentName,
              imageURL,
              bannerURL,
              eventLocation: formData.eventLocation,
              GameId: game.id,
              GameSlug: game.slug,
              videoProviderData: playlistInfo,
              tier,
              ...matchData,
            };
            eventData.admins = event
              ? JSON.parse(eventData.admins)
              : {
                  [uid]: {
                    rank: "creator",
                    addedAt: new Date().getTime(),
                  },
                };
            const res = await postToApi(
              `/events/${event ? "update" : "create"}`,
              eventData,
              "POST",
              {
                authorization: token,
                authmethod: determineAuthMethod(game),
              }
            );
            analytics.save("create_tournament_result", {
              formData: eventData,
              response: res,
              error: res.error,
            });
            if (res.error) return window.alert(res.error.message);
            event
              ? window.open(`/event/${game.slug}/${event.id}`, "_self")
              : history.push(`/event/${game.slug}/`);
            closeModal();
          };
          user && authToken
            ? postEventToApi(authToken, user.uid)
            : setModal(
                <AuthModal
                  onLogin={({ authToken, privateInfo }) =>
                    postEventToApi(authToken, privateInfo.uid)
                  }
                />
              );
        }}
        onCancel={closeModal}
      />
    </Modal>
  );
};

export default EventUploadModal;

const fetchPlaylistData = async (playlistURL, GameSlug) => {
  const playlistId = getPlaylistIdFromUrl(playlistURL);
  const res = await fetchFromApi(
    `/matches/format-playlist/${GameSlug}/${playlistId}`
  );
  return res;
};

const isValidURL = (playlistURL) => {
  const isURL = strings.URL_REGEX.test(playlistURL);
  return isURL && playlistURL.includes("list=");
};

const getPlaylistIdFromUrl = (playlistUrl) => {
  if (!playlistUrl) return null;
  const matches = playlistUrl.match(/list=(.+)[/&]?/);
  return matches && matches[1].replace(/[/&].+/g, "");
};

const getBestThumb = (t) => {
  const thumb = t.standard || t.high || t.medium || t.default;
  return thumb.url;
};
