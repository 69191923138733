import React, { useContext } from "react";
import { useParams, Link } from "react-router-dom";

import { UserContext } from "../../../context/UserContext";
import { analytics, strings } from "../../../helpers";
import { useModal } from "../../../hooks";
import { FollowGameModal } from "../../modals";
import { Icon } from "..";
import "./FollowedGamesList.scss";

const FollowedGamesList = ({
  useShortname = false,
  games,
  header = "My Games",
  searchButton = true,
  children,
}) => {
  const { user } = useContext(UserContext);
  const followedGames =
    games ||
    (user && user.followedGames
      ? Object.keys(user.followedGames).map((slug) => {
          const game = user.followedGames[slug];
          game.slug = slug;
          return game;
        })
      : []);

  const { setModal } = useModal();
  return (
    <div className="FollowedGamesList">
      <h3 className="header text-bold">{header}</h3>
      <div className="Divider mb-2" />
      <ul>
        {followedGames && followedGames.length ? (
          followedGames.map((g) => (
            <FollowedListItem
              key={g.slug}
              game={g}
              notifCount={0}
              useShortname={useShortname}
            />
          ))
        ) : (
          <div className="pl-5">You aren't following any games yet</div>
        )}
      </ul>
      {searchButton && (
        <button
          onClick={() => {
            analytics.save("follow_new_game_clicked", { screen: "SideMenu" });
            setModal(<FollowGameModal />);
          }}
          className="primary icon-left ml-5 px-3"
        >
          <Icon name="search-outline" />
          {followedGames && followedGames.length ? "FIND MORE" : "FIND GAMES"}
        </button>
      )}
      {children}
    </div>
  );
};

export default FollowedGamesList;

const FollowedListItem = ({
  game,
  notifCount = 0,
  useShortname,
  className,
}) => {
  const { gameSlug } = useParams();
  let { name, shortname, iconURL, slug } = game;
  name = name || (useShortname && shortname) || strings.slugToTitle(slug);
  return (
    <li>
      <Link
        to={`/game/${slug}`}
        onClick={() => analytics.save("followed_game_clicked", { game })}
      >
        <div
          className={`FollowedListItem flex-middle flex-between ${className} ${
            slug !== gameSlug || "active-game"
          } pl-4 h-12 text-lg`}
        >
          <span className="flex-middle">
            <img src={iconURL} alt="" className="w-8 mr-2" />
            {name}
          </span>
          {notifCount > 0 && (
            <div className="tourney-notif" title={`New tournaments!`}>
              <Icon name="trophy" className="warning" />
              <div className="Badge small bg-danger">{notifCount}</div>
            </div>
          )}
        </div>
      </Link>
    </li>
  );
};
