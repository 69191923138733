import React, { useState, useEffect } from "react";

import { strings } from "../../../helpers";
import SearchInput from "../SearchInput/SearchInput";

const ClassSearchInput = ({
  gameClasses = {},
  onSelect,
  label,
  initialCharacters,
  showSpinner = true,
  className,
  ...props
}) => {
  const [charSearchRes, setCharSearchRes] = useState(null);
  const [selectedChars, setSelectedChars] = useState(initialCharacters || []);

  useEffect(
    () => setSelectedChars(initialCharacters || []),
    [initialCharacters]
  );

  // eslint-disable-next-line
  useEffect(() => onSelect(selectedChars), [selectedChars]);

  const classNamesArr =
    gameClasses && gameClasses.classes ? Object.keys(gameClasses.classes) : [];
  const ClassIcon = ({ gameClass, onClick }) => (
    <span onClick={onClick}>
      {gameClass && gameClasses.classes && gameClasses.classes[gameClass] ? (
        <img
          className="h-5"
          src={gameClasses.classes[gameClass].iconURL}
          title={gameClass}
          alt=""
        />
      ) : (
        <span>{gameClass}</span>
      )}
    </span>
  );
  const [defaultInput, setDefaultInput] = useState("");
  return (
    <SearchInput
      {...props}
      icon={null}
      className={className}
      debounce={0}
      placeholder={defaultInput}
      label={
        <span>
          {label || strings.pluralize(gameClasses?.classDisplayName || "Class")}
          :{" "}
          {selectedChars &&
            selectedChars.map((c) => (
              <ClassIcon
                key={c}
                gameClass={c}
                onClick={() => {
                  setSelectedChars(selectedChars.filter((char) => char !== c));
                  setDefaultInput(c);
                }}
              />
            ))}
        </span>
      }
      onSearch={(query) =>
        query
          ? setCharSearchRes(
              classNamesArr
                .filter(
                  (name) =>
                    !selectedChars.includes(name) &&
                    name.toLowerCase().includes(query.toLowerCase())
                )
                .slice(0, 4)
            )
          : setCharSearchRes()
      }
      onSubmit={
        charSearchRes
          ? (gameClass) => {
              gameClass && setSelectedChars([...selectedChars, gameClass]);
              setDefaultInput();
            }
          : null
      }
      searchResults={charSearchRes}
      showSpinner={showSpinner}
      renderResult={(gameClass, i) => (
        <div
          key={gameClass}
          className="flex-middle text-base dark-always px-2"
          onClick={() => setSelectedChars([...selectedChars, gameClass])}
          id={`search-res-${i}`}
        >
          <ClassIcon gameClass={gameClass} />
          <p className="ml-2">{gameClass}</p>
        </div>
      )}
    />
  );
};

export default ClassSearchInput;
