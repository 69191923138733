import React, { useContext } from "react";

import { UserContext } from "../../../context/UserContext";
import { analytics } from "../../../helpers";
import { useFollowGame, useModal } from "../../../hooks";
import { AuthModal } from "../../modals";
import { Icon } from "../../reusable";

const FollowButton = ({ game }) => {
  const { setModal } = useModal();
  const { user } = useContext(UserContext);
  const [isFollowing, setIsFollowing] = useFollowGame(game.slug);
  if (!game) return <span />;

  return isFollowing ? (
    <button
      onClick={() => {
        window.confirm(`Unfollow ${game.name}?`) && setIsFollowing(game, true);
        analytics.save("follow_game", game);
      }}
      className="icon-left fill ml-8"
    >
      <Icon name="checkmark-outline"></Icon>
      FOLLOWED
    </button>
  ) : (
    <button
      className="ml-8"
      onClick={() =>
        user
          ? setIsFollowing(game)
          : setModal(
              <AuthModal
                onLogin={(user) => setIsFollowing(game, false, user.uid)}
              />
            )
      }
    >
      FOLLOW
    </button>
  );
};

export default FollowButton;
