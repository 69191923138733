import React, { useContext } from "react";
import { Link } from "react-router-dom";

import { useWatchHistory } from "../../hooks";
import { UserContext } from "../../context/UserContext";
import { getCachedGameIcon } from "../../helpers/cacheHelper";
import { Icon } from "../reusable";

const KeepWatchingList = () => {
  const [watchedEvents] = useWatchHistory();
  const { user } = useContext(UserContext);

  return user && watchedEvents.length ? (
    <div className="FollowedGamesList KeepWatching">
      <h3 className="header text-bold">Keep Watching</h3>
      <div className="Divider mb-2" />
      <ul>
        {watchedEvents.slice(0, 3).map((e) => (
          <li key={e.id}>
            <Link to={`/video/${e.watchHistory.lastWatched.matchId}`}>
              <div
                title={e.name}
                className={`${LIST_ITEM_BASE} flex-between history-item`}
              >
                <img
                  className="h-8"
                  alt="game-icon"
                  src={getCachedGameIcon(e.GameSlug)}
                />
                <div className="history-item-text">{e.name}</div>
              </div>
            </Link>
          </li>
        ))}
        {watchedEvents.length > 3 && (
          <li>
            <Link to="/history">
              <div className={LIST_ITEM_BASE}>
                <Icon name="book-outline" className="flex-middle" />
                <span className="mx-2">All History</span>
              </div>
            </Link>
          </li>
        )}
      </ul>
    </div>
  ) : null;
};

export default KeepWatchingList;

const LIST_ITEM_BASE = `FollowedListItem pl-4 h-12 text-lg flex-middle`;
