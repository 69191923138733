import React from "react";

import "./Toggle.scss";

const Toggle = ({ onChange, value, disabled }) => {
  return (
    <span className="Toggle">
      <label className="checker">
        <input
          className="checkbox"
          type="checkbox"
          onChange={(e) => onChange && onChange(e.target.value)}
          checked={value}
          disabled={disabled}
        />
        <div className="check-bg"></div>
        <div className="checkmark">
          <svg viewBox="0 0 100 100">
            <path
              d="M20,55 L40,75 L77,27"
              fill="none"
              stroke="#FFF"
              strokeWidth="15"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </label>
    </span>
  );
};

export default Toggle;
