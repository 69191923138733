import { useEffect, useState } from "react";

import { API_URL } from "../config/config";
import { strings } from "../helpers";

const useApi = (endpoint, options, fieldsToParse = []) => {
  const [state, setState] = useState({
    data: null,
    loading: true,
    error: null,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!endpoint) return;
        const json = await fetchFromApi(endpoint, options, fieldsToParse);
        setState({ data: json, loading: false });
      } catch (error) {
        debugger;
        console.log("err fetching:", error);
        setState({ error });
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, [endpoint, options]);

  return [state.data, state.error, state.loading];
};

export default useApi;

export const fetchFromApi = (endpoint, options, fieldsToParse = []) => {
  if ((!options || options.method === "GET") && apiCache[endpoint]) {
    console.log("returning data from cache for: ", endpoint);
    const cachedData = apiCache[endpoint];
    // copy the data so altering the response won't fuck up cache
    return new Promise((resolve) =>
      resolve(
        Array.isArray(cachedData) ? cloneArr(cachedData) : { ...cachedData }
      )
    );
  }
  const url = `${API_URL}${endpoint}`;
  return fetchJson(url, options).then((res) => {
    res &&
      fieldsToParse.forEach((field) => {
        res[field] = strings.parseIfJSON(res[field]);
      });
    apiCache[endpoint] = Array.isArray(res)
      ? cloneArr(res)
      : typeof res === "object"
      ? { ...res }
      : res;
    return res;
  });
};

export const postToApi = (endpoint, data, method = "POST", headerData = {}) => {
  const body = data ? JSON.stringify(cleanNullValues(data)) : null;
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  Object.keys(headerData).forEach((key) => {
    headers.append(key, headerData[key]);
  });

  const requestOptions = {
    method,
    headers,
    body,
    // redirect: "follow",
  };
  return fetchFromApi(endpoint, requestOptions);
};

const fetchJson = async (url, options) => {
  const res = await fetch(url, options);
  const json = await res.json();
  return json;
};

const cleanNullValues = (postData = {}) => {
  if (!postData) return postData;
  const cleanData = {};
  Object.keys(postData).forEach((key) => {
    const val = postData[key];
    if (val || val === false) {
      cleanData[key] = val;
    }
  });
  return cleanData;
};

const apiCache = {};
export const clearCacheAtEndpoint = (ep) => delete apiCache[ep];

export const usePaginatedApi = (
  endpoint,
  initialPage = 1,
  options,
  fieldsToParse,
  newPageFrequency = 500
) => {
  const initialState = {
    data: { docs: [], pages: 1, total: 0 },
    loading: true,
    error: null,
    lastLoadedAt: null,
    page: 1,
    highestPage: 1,
  };
  const [state, setState] = useState(initialState);

  const fetchData = async (page = 1, isNew = false) => {
    try {
      if (!endpoint) return;
      if (!isNew && page > state.data.pages)
        throw new Error("Cant fetch > total");
      const endpointWithPage = `${endpoint}${
        endpoint.includes("?") ? "&" : "?"
      }page=${page}`;
      const json = await fetchFromApi(endpointWithPage, options, fieldsToParse);
      json.docs = isNew
        ? json.docs.slice(0)
        : page < state.highestPage
        ? json.docs.concat(state.data.docs)
        : state.data.docs.concat(json.docs);
      setState({
        data: json,
        loading: false,
        lastLoadedAt: new Date().getTime(),
        page,
        highestPage: page > state.highestPage ? page : state.highestPage,
      });
      return json.docs;
    } catch (error) {
      console.log("PAGINATE ERROR:", error);
      setState({ error });
    }
  };

  useEffect(() => {
    setState(initialState);
    fetchData(initialPage, true);
  }, [endpoint]); //eslint-disable-line

  const getNextPage = async (page = 1) => {
    return await fetchData(page);
  };

  const {
    data: { docs, total, pages },
    error,
    loading,
    highestPage,
  } = state;
  const hasMore = highestPage < pages && total > docs.length;
  return [
    docs,
    hasMore ? getNextPage : null,
    total,
    error,
    loading,
    getNextPage, // for playlist, loading prev pages when end reached
  ];
};

const cloneArr = (arr) =>
  arr.findIndex((a) => typeof a !== "object") > -1
    ? [...arr]
    : arr.map((obj) => ({ ...obj }));
