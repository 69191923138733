import React, { useEffect, useState } from "react";

import { cache } from "../../../helpers";
import { LoginModal, RegisterModal } from "../index";

const AuthModal = (props) => {
  const [Component, setComponent] = useState(<LoginModal {...props} />);

  useEffect(
    () =>
      setComponent(
        cache.getCache("HAS_CREATED_ACCOUNT") ? (
          <LoginModal {...props} />
        ) : (
          <RegisterModal {...props} />
        )
      ),
    [props]
  );

  return Component;
};

export default AuthModal;
