import React, { useState, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Link,
} from "react-router-dom";
import { hot } from "react-hot-loader";
import "@firebase/analytics";

import { AppContextProvider } from "./context/AppContext";
import { UserContextProvider, UserContext } from "./context/UserContext";
import { useModal } from "./hooks";
import { fetchFromApi } from "./hooks/useApi";
import { CLIENT_VERSION } from "./config/config";
import { analytics } from "./helpers";
import EventScreen from "./screens/EventScreen/EventScreen";
import GameScreen from "./screens/GameScreen/GameScreen";
import HomeFeed from "./screens/HomeFeed/HomeFeed";
import MyGamesScreen from "./screens/MyGamesScreen/MyGamesScreen";
import PlayerProfileScreen from "./screens/PlayerProfileScreen/PlayerProfileScreen";
import Navbar from "./components/Navbar/Navbar";
import SuperAdminScreen from "./screens/SuperAdminScreen/SuperAdminScreen";
import UnsubscribeScreen from "./screens/UnsubscribeScreen/UnsubscribeScreen";
import VideoScreen from "./screens/VideoScreen/VideoScreen";
import WatchHistoryScreen from "./screens/WatchHistoryScreen/WatchHistoryScreen";
// import SideDrawer from './components/SideDrawer/SideDrawer';
import { Icon } from "./components/reusable";
import logo from "./assets/images/logo_pink.webp";

console.log("CLIENT_VERSION: ", CLIENT_VERSION);

function App() {
  return (
    <AppContextProvider>
      <UserContextProvider>
        <AppRouter />
      </UserContextProvider>
    </AppContextProvider>
  );
}

export default hot(module)(App);

const AppRouter = () => (
  <Router>
    <Routes />
  </Router>
);

const Routes = () => {
  const { modalContent } = useModal() || {};
  const loc = useLocation();
  const hideNav = loc.pathname.startsWith(`/unsubscribe`);

  return (
    <>
      {modalContent}
      {!hideNav && <Nav />}
      <div className="AppContent">
        <Switch>
          <Screen path="/unsubscribe/:uid" component={UnsubscribeScreen} />
          <Screen path="/event/:gameSlug/:eventId" component={EventScreen} />
          <Screen path="/game/:gameSlug" component={GameScreen} />
          <Screen path="/video/:matchId" component={VideoScreen} />
          <Screen path="/my-games" component={MyGamesScreen} />
          <Screen path="/history" component={WatchHistoryScreen} />
          <Screen
            path="/player/:gameSlug/:playerSlug"
            component={PlayerProfileScreen}
          />
          <Screen path="/superadmin" component={SuperAdminScreen} />
          <Screen path="/" component={HomeFeed} />
        </Switch>
      </div>
      {!hideNav && <MobileTabNav />}
    </>
  );
};

const Screen = ({ path, component: Component, showNav = true, ...props }) => (
  <Route path={path}>
    <Component />
  </Route>
);

const Nav = () => {
  const [searchRes, setSearchRes] = useState(null);
  const user = useContext(UserContext).user;

  return (
    <Navbar
      title="GGvods"
      showNotifs={false}
      logo={logo}
      onSearch={async (query) => {
        if (!query) return setSearchRes(null);
        const searchRes = await mainSearch(query);
        analytics.save("search_main", { query, results: searchRes });
        setSearchRes(searchRes);
      }}
      searchResults={searchRes}
      user={user}
      // renderDrawer={(props) => <SideDrawer {...props} />}
    />
  );
};

const mainSearch = async (query) => {
  const res = await fetchFromApi(`/search/${query}`);
  return [...res.games, ...res.events];
};

const MobileTabNav = () => {
  const [hide, setHide] = useState(false);
  const IS_TINY = window.innerWidth < 327;

  useEffect(() => {
    document.body.addEventListener(
      "focusin",
      (e) => e.target.type === "text" && setHide(true)
    );
    document.body.addEventListener("focusout", () => setHide(false));
  }, []);
  return (
    <div className={`${hide ? "hidden " : ""}MobileTabNav flex-middle px-8`}>
      <TabOption name="Home" icon="home" to="/" />
      <TabOption
        name="Search"
        icon="search"
        onClick={() => document.getElementById("Navbar-search").focus()}
      />
      <TabOption
        name={IS_TINY ? `Games` : `My Games`}
        icon="Heart"
        to="/my-games"
      />
    </div>
  );
};

const TabOption = ({ name, icon, to, onClick }) => {
  const location = useLocation();
  const isActive = to === location.pathname;
  const IconAndName = () => (
    <>
      <Icon name={icon} />
      <div className="TabOption-name info text-sm">{name}</div>
    </>
  );
  const containerClass = `flex-column flex-middle px-2 ${
    isActive ? "active" : ""
  }`;
  return (
    <div className="TabOption flex-center w-1-3">
      {name === "Search" ? (
        <div className={containerClass} onClick={onClick}>
          <IconAndName />
        </div>
      ) : (
        <Link to={to || location.pathname} className={containerClass}>
          <IconAndName />
        </Link>
      )}
    </div>
  );
};
